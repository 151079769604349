import React from 'react';
import uuid from 'react-uuid';
//==================================================================
import Markdown from 'markdown-to-jsx';
import Chart from '../components/ui/Chart';
import ChartMd from '../components/ui/ChartMd';
import Countdown from '../components/ui/Countdown.js';
import MetaData from '../components/utils/MetaData';
import Img from '../components/ui/Img';
import SocialMedia from '../components/ui/SocialMediaVideo.js';
import HighLightBox from '../components/ui/HighLightBox';
import ImgBox from '../components/ui/ImgBox';
import ImgBoxMultiPicture from '../components/ui/ImgBoxMultiPicture';
import ImgSliderBox from '../components/ui/ImgSliderBox';
import LinkEx from '../components/utils/LinkEx.js';
import LinkJS from '../components/utils/Link.js';
import H1 from '../components/utils/H1';
import DateToContent from '../components/utils/DateToContent';

export const markdown = (md, omitMeta, date) => {
  return (
    <Markdown
      key={uuid()}
      options={{
        overrides: {
          Chart: { component: Chart },
          ChartMd: { component: ChartMd },
          Countdown: { component: Countdown },
          HighLightBox: { component: HighLightBox },
          Img: { component: Img, props: { omitMeta } },
          SocialMedia: { component: SocialMedia, props: { omitMeta } },
          ImgBox: { component: ImgBox, props: { omitMeta } },
          ImgBoxMultiPicture: {
            component: ImgBoxMultiPicture,
            props: { omitMeta },
          },
          ImgSliderBox: { component: ImgSliderBox, props: { omitMeta } },
          LinkEx: { component: LinkEx },
          Link: { component: LinkJS },
          // h1 to h2 and omit meta for all blog posts cards
          H1: { component: H1, props: { omitMeta } },
          MetaData: {
            component: MetaData,
            props: omitMeta
              ? { title: false, description: false, omitMeta: true }
              : {},
          },
          Date: { component: DateToContent, props: { omitMeta, date } },
        },
      }}
    >
      {md}
    </Markdown>
  );
};

export const handleLinkClick = url => {
  if (url.startsWith('https://')) {
    window.open(url, '_blank');
  } else {
    window.open(`${process.env.PUBLIC_URL}${url}`, '_self');
  }
};

export const splitPropToArray = element => {
  if (element === undefined) return [];
  return element.split(',').map(el => el.replace(/\n/g, ' '));
};

export const splitPropByAsteriskToArray = element => {
  if (element === undefined) return [];
  return element
    .split('*')
    .map(el => el.replace(/\n/g, ' '))
    .map(el => el.replace(/[}{"'/\\]/g, ''))
    .filter(el => el !== ' ');
};

export const dotToComma = element => {
  if (element === undefined) return [];
  return element.map(el => el.replace(/\./g, ',').replace(/,,/g, '.'));
};

export const dateChangedOrder = date => {
  if (date === undefined || date === '') return false;
  const array = date.split('.');
  [array[1], array[0]] = [array[0], array[1]];
  return array.join('.');
};

export const dateToUnixTime = date => {
  return Date.parse(date);
};

export const disableIfCardComponent = (omitMeta, preView) => {
  if (omitMeta) {
    if (!preView || preView === undefined) return false;
  }
  return true;
};
