import React, { useState, useEffect } from 'react';
import classes from './DateToContent.module.css';
// environment
import { config } from '../../utils/constants';
import { dateChangedOrder, dateToUnixTime } from '../../utils/markdown';
const DateToContent = props => {
  const createdAt = dateChangedOrder(props.date.created);
  const updatedAt = dateChangedOrder(props.update);
  const unixOneYear = 31536000000;

  const checkDateLastUpdated = (date, update) => {
    // waring in dev mode
    // if (!config.dev) return;
    // if (!update) {
    //   // if created more than one year ago,
    //   if (date <= Date.now() - unixOneYear) {
    //     return console.log('❌❌Date❌❌ Content needs update');
    //   }
    // }
    // if (update <= Date.now() - unixOneYear) {
    //   return console.log('❌❌Update❌❌ Content needs update');
    // }
  };

  checkDateLastUpdated(dateToUnixTime(createdAt), dateToUnixTime(updatedAt));
  //==================================================================
  return (
    <div
      className={`${classes.dateBox} ${props.hide && classes.hide} ${
        props.omitMeta && classes.preview // omitMeta handles layout change for blog view
      }`}
      id="dateBox"
    >
      {props.update && (
        <div className={classes.pBox}>
          <p>Aktualisiert:</p>
          <p id="updatedAt" key={updatedAt}>
            {props.update}
          </p>
        </div>
      )}
      {props.date && (
        <div
          className={`${classes.pBox} ${
            props.update && props.onlyUpdated && classes.transparent
          }`}
        >
          <p>Veröffentlicht: </p>
          <p id="createdAt" key={createdAt}>
            {props.date?.created}
          </p>
        </div>
      )}
    </div>
  );
};

export default DateToContent;
