import React from 'react';
import classes from './Paypal.module.css';
import paypal from '../../assets/img/paypal.png';

const Paypal = () => {
  return (
    <div className={classes.paypal}>
      <div className={classes.paypalHeading}>
        <h2>Herzlichen Dank an alle Unterstützer,</h2>
        <h4>
          Ihr seid großartig und motiviert mich diesen Blog weiter zu betreiben.
        </h4>
      </div>
      <div className={classes.paypalButtonDiv}>
        <h5>Werde auch du ein Unterstützer, klicke auf den link...</h5>
        <div
          className={classes.paypalButton}
          onClick={() => {
            window.open('http://paypal.me/saschagoebbels', '_blank');
          }}
        >
          <img src={paypal} alt="PayPal" className={classes.img} />
        </div>
      </div>
    </div>
  );
};

export default Paypal;
