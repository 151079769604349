import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// environment
import { config } from '../../utils/constants';

const MetaData = props => {
  const checked = props?.checked === true ? true : false;
  const checkedOmit = props?.checked === 'omit' ? true : false;
  const title = props?.title?.length;
  const description = props?.description?.length;
  //==================================================================
  return (
    <div id="metaData">
      {config.dev && !props.omitMeta && (
        <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
          <p>
            {55 < title && title < 71
              ? `Title: ${70 - title} ✅`
              : `Title: ${70 - title} 🛑`}
          </p>
          <p>
            {145 < description && description < 161
              ? `Description: ${160 - description} ✅`
              : `Description: ${160 - description} 🛑`}
          </p>
          {checked && !checkedOmit && <p>checked ✅</p>}
          {!checked && !checkedOmit && <p>not checked 🛑</p>}
        </div>
      )}
      <HelmetProvider>
        <Helmet>
          {/* // if dev mode set to React App and omit meta for blog card thats why check props.title true */}
          {!props?.omitMeta && (
            <title>{config.dev ? 'React App' : props.title}</title>
          )}
          {props?.description && (
            <meta name="description" content={props?.description} />
          )}
        </Helmet>
      </HelmetProvider>
    </div>
  );
};

export default MetaData;
