export const fetchMd = async path => {
  let content;
  await fetch(path)
    .then(res => {
      content = res.text();
    })
    .catch(() => {
      console.log('❌Can´t fetch Md Data❌');
      content = '# Error 404 ! ## File not found';
    });
  return content;
};
