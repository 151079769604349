const template = {
  gridTemplateColumns:
    'minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, 15rem)',
  tableHeader: { text: 'Header Text - This Heading', h: '3' },
  rowHeader: { row: ['head1', 'head2', 'head3'] },
  content: [
    { row: ['Row 1', 1, 2] },
    { row: ['Row 2', 1, 2] },
    { row: ['Row 3', 1, 2] },
  ],
  rowFooter: { row: ['', 'Sum', '12345'] },
};

export const fahrzeugKategorie = {
  gridTemplateColumns:
    'minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto) ',
  tableHeader: {
    text: '',
    h: '3',
  },
  rowHeader: {
    row: [
      '',
      'Bulli',
      'Kastenwagen',
      'Alkoven',
      'Teilintegriert',
      'Vollintegriert',
    ],
  },
  content: [
    { row: ['Alltagstauglich ggf. als Zweitwagen', '++', '+', '-', '-', '-'] },
    { row: ['Reisegeschwindigkeit', '++', '+', '-', '+', '-'] },
    { row: ['Kraftstoffverbrauch', '++', '+', '-', '+', '-'] },
    { row: ['Stauraum', '--', '-', '++', '+', '++'] },
    { row: ['Anzahl der Schlafplätze', '-', '-', '+', '-', '+'] },
    { row: ['Flexibilität', '++', '+', '-', '+', '-'] },
    { row: ['Komfort', '-', '-', '++', '+', '++'] },
    { row: ['zGG bis 3,5to', '++', '+', '-', '+', '--'] },
  ],
  // rowFooter: { row: ['', '', 'Summe', '4320 €', '2628 €', '1314 €'] },
};
