export const nav = {
  page: 'navigation',
  displayName: 'Navigation',
  inProgress: false, //only navbar !
  offline: false, //only navbar !
  template: {
    page: '',
    displayName: '',
    dropdown: [],
  },
  dropdown: [
    // // // Home
    {
      page: 'home',
      displayName: 'Home',
      dropdown: [],
    },

    // // // Womo
    {
      page: 'womo',
      displayName: 'Womo',
      dropdown: [
        {
          page: 'womo/wohnmobil-erstausstattung',
          displayName: 'Wohnmobil Erstausstattung',
          dropdown: [],
        },
        {
          page: 'womo/welches-wohnmobil-passt-zu-uns',
          displayName: 'Welches Wohnmobil passt zu uns',
          dropdown: [],
        },
        {
          page: 'womo/must-have-camping-gadgets',
          displayName: 'Must have Camping-Gadgets',
          dropdown: [],
        },
      ],
    },
    // // // Technik
    {
      page: 'technik',
      displayName: 'Technik',
      dropdown: [
        // {
        //   page: 'technik/solar',
        //   displayName: 'Solar',
        //   dropdown: [],
        // },
        // {
        //   page: 'technik/kabel',
        //   displayName: 'Kabelquerschnitt',
        //   dropdown: [],
        // },
        {
          page: 'technik/wechselrichter',
          displayName: 'Wechselrichter',
          dropdown: [],
        },
      ],
    },
    // // // Blog
    {
      page: 'blog',
      displayName: 'Blog',
      dropdown: [
        {
          page: 'blog/alle-blog-posts',
          displayName: 'Alle Blog Artikel',
          dropdown: [],
        },
        {
          page: 'blog?wdtag=story',
          displayName: 'Story',
          dropdown: [],
        },
        {
          // page: 'social-media',
          page: 'blog?wdtag=social-media',
          // offline: true, //only navbar !
          displayName: 'Social Media',
          dropdown: [],
        },
        {
          page: 'blog/wie-kann-ich-blogger-werden',
          offline: true, //only navbar !
          displayName: 'Blogger werden',
          dropdown: [],
        },
        {
          page: 'blog/geld-verdienen-mit-einem-blog',
          offline: true, //only navbar !
          displayName: 'Geld verdienen mit einem Blog',
          dropdown: [],
        },
      ],
    },
    // // // Map
    {
      page: 'karte',
      displayName: 'Karte',
      dropdown: [],
    },
    // // // Auszeit
    {
      page: 'auszeit',
      displayName: 'Auszeit',
      dropdown: [
        {
          page: 'auszeit/elternzeit',
          displayName: 'Elternzeit',
          dropdown: [],
        },
        {
          page: 'auszeit/elterngeld',
          displayName: 'Elterngeld',
          dropdown: [],
        },
        {
          page: 'auszeit/anleitung',
          displayName: 'Anleitung',
          dropdown: [],
        },
        {
          page: 'auszeit/varianten-und-finanzen',
          displayName: 'Varianten & Finanzen',
          dropdown: [],
        },
        {
          page: 'auszeit/unsere-persönliche-budgetplanung',
          displayName: 'Unser Budgetplanung',
          dropdown: [],
        },
      ],
    },
    // // // Story

    // // // About-Me
    {
      page: 'about-me',
      displayName: 'About-Me',
      dropdown: [],
    },
    {
      page: 'test',
      displayName: 'TEST-1',
      offline: true, //only navbar !
      dropdown: [],
    },
    {
      page: 'test2',
      displayName: 'TEST-2',
      offline: true, //only navbar !
      dropdown: [],
    },
  ],
};
