import React from 'react';
import { useState, useEffect } from 'react';
import classes from './App.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsUpToLine } from '@fortawesome/free-solid-svg-icons';

import CookiesForm from './components/ui/CookiesForm';
import { useCookies } from 'react-cookie';
// loading logic
import { toggleLoading } from './utils/loading';
// handle markdown
import { scrollToContent, setSlugId } from './utils/scrollToContent.js';

// handle render
import { renderContent } from './utils/renderContent.js';
// params Id translate To Page
import { paramsIdToPage } from './utils/paramsIdToPage.js';
// components
import Page from './components/page/Page';

// data
import { contentObject } from './data/contentObject';
import { config } from './utils/constants';

// react router
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

//google analytics
// eslint-disable-next-line no-unused-vars
import ReactGA from 'react-ga4';

// Store
import { useSnapshot } from 'valtio';
import { store } from './store/store';
// animations
import { AnimatePresence } from 'framer-motion';

function App() {
  //==================================================================
  let { page } = useParams();
  const snap = useSnapshot(store);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  //==================================================================

  const [currentPage, setCurrentPage] = useState('home');
  // check id and load content if no id go home
  const [contentState, setContentState] = useState([]);
  const [sidebarOn, setSidebarOn] = useState(true);

  const [modalOn, setModalOn] = useState(false);

  // on start up set page to home
  useEffect(() => {
    // set slug ids to target HTML elements with id to scroll
    setSlugId();
    // load google analytics and cookies if accepted
    if (cookies.cookieConsent === true) {
      handleAcceptCookie();
    }
    // cookie delay because web crawler and search bots
    setTimeout(() => setCookiesDelay(true), 2000);
  }, []);

  //==================================================================
  // handle cookies
  const [cookies] = useCookies(['cookieConsent']);
  const [cookieBox, setCookieBox] = useState(true);
  const [cookiesDelay, setCookiesDelay] = useState(false);

  // cookie banner
  const changeCookieState = (cookies, page) => {
    if (!cookies.cookieConsent || page === 'cookies') return setCookieBox(true);
    setCookieBox(false);
  };
  // google analytics & other cookies
  const handleAcceptCookie = () => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      // ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };
  useEffect(() => {
    changeCookieState(cookies, page);
    toggleLoading({ page, lastPage: snap.page, timer: 400 });
  }, [cookies.cookieConsent, page]);
  //==================================================================
  // after loading page inject id´s from md
  const waitLoad = () => {
    setSlugId();
    // if search params active, scroll on reload to text id
    if (searchParams.get('wdtextid')) {
      scrollToContent(searchParams.get('wdtextid'));
    }
  };
  window.onload = setTimeout(waitLoad, 50);
  //==================================================================
  //==================================================================
  // on params change get current object
  useEffect(() => {
    // translate url if needed
    const pageKey = paramsIdToPage(contentObject, page);
    // load current page
    getCurrentContentObject(pageKey, contentObject);
    setSlugId();
    if (searchParams.get('wdtextid') === null) {
      // omit scroll to top in development
      config.scrollOmitDev &&
        document.getElementById('appPage').scrollTo({ top: 0 });
    }
  }, [page]);
  // on params change load content
  useEffect(() => {
    setRenderContent();
  }, [currentPage]);

  //==================================================================
  const getCurrentContentObject = (page, contentObject) => {
    // create obj with currentPage
    const obj = contentObject[page];
    // // catch if objectKey not exists
    if (obj === undefined) {
      setCurrentPage(contentObject['error']);
      return;
    }
    // check contentObject for sidebarState
    obj.sidebarOff === true ? setSidebarOn(false) : setSidebarOn(true);
    // check offline state and redirect to in progress
    if (obj.offline) {
      navigate('/in-progress');
    }
    // if all checks ok set current page
    setCurrentPage(obj);
    return;
  };

  //==================================================================
  const setRenderContent = async () => {
    const content = await renderContent(
      //
      currentPage === undefined
        ? []
        : { content: currentPage.content, date: currentPage.date }
    );
    setContentState(content);
  };

  //==================================================================
  // handle scroll to top button behaviors
  const pageDiv = document.getElementById('appPage');
  const [scrollButton, setScrollButton] = useState(true);

  const checkScrollPosition = () => {
    if (pageDiv === undefined || pageDiv === null) return;
    const currentScrollPos = pageDiv.scrollTop;
    if (currentScrollPos > 250) {
      setScrollButton(false);
    } else {
      setScrollButton(true);
    }
  };

  //==================================================================
  // modal window
  useEffect(() => {
    if (pageDiv === undefined || pageDiv === null) return;
    pageDiv.scrollTo({ top: 0 });
    setModalOn(snap.modal);
  }, [snap.modal]);
  //==================================================================
  //==================================================================
  return (
    <div
      className={`${classes.App} ${modalOn && classes.modalOn}`}
      onScroll={() => {
        checkScrollPosition();
      }}
      id={'appPage'} // necessary scroll to content
    >
      {/* <div> */}
      {/* {snap.loading && (
        <Loading show={true} loader={0} random={false}></Loading>
      )} */}
      <AnimatePresence>
        {cookieBox && cookiesDelay && (
          <CookiesForm
            cookieConsent={cookies.cookieConsent}
            pageIdCookies={page === 'cookies' ? true : false}
          />
        )}
      </AnimatePresence>
      <Page
        content={contentState}
        dev={config.dev}
        currentPage={currentPage}
        sidebarToggle={sidebarOn}
        key={'pageKey'}
        data={contentObject}
        toggleLoading={toggleLoading}
      />
      <button
        className={`${classes.divScrollButton} ${classes.safari} ${
          scrollButton && classes['divScrollButton--hide']
        }`}
        onClick={() => {
          setSearchParams();
          pageDiv.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        <FontAwesomeIcon icon={faArrowsUpToLine} />
      </button>
      {/* </div> */}
    </div>
  );
}

export default App;
