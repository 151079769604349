/* eslint-disable react/prop-types */
import React from 'react';
import { useState, useEffect } from 'react';
import classes from './NavbarMobile.module.css';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const NavbarMobile = props => {
  const [backButton, setBackButton] = useState(false);

  useEffect(() => {
    if (props.nav.page === 'navigation') {
      setBackButton(true);
    }
  }, []);

  useEffect(() => {}, []);

  return (
    <ul
      className={`${classes.navbarMobile} ${
        props.menu && classes.showNavbarMobile
      }`}
      // style={{ height: `${props.pageHeight}px` }}
      id={props.id}
    >
      {backButton && (
        <div
          onClick={() => {
            props.toggleMenu(false);
          }}
          className={`${classes.headingAndClose} ${classes.x}`}
          key={close}
        >
          <FontAwesomeIcon className={classes.backIcon} icon={faXmark} />
        </div>
      )}
      {!backButton && (
        <div
          onClick={() => {
            props.closeDropdown(props.nav.page, false);
          }}
          className={classes.headingAndClose}
          key={close}
        >
          {props.nav.displayName}
          <FontAwesomeIcon className={classes.backIcon} icon={faArrowRight} />
        </div>
      )}
      {props.navFilteredOfflineElements.map(navElementMobile => {
        return (
          <li
            key={navElementMobile.page}
            id={navElementMobile.page}
            className={classes.divNavElementMobile}
          >
            <Link
              className={`${classes.mainMobile} ${classes.navElementMobile}`}
              to={`/${
                navElementMobile.inProgress === true
                  ? 'inProgress'
                  : navElementMobile.page
              }`}
            >
              <div
                className={`${
                  navElementMobile.inProgress && classes.textCrossedOut
                }`}
                onClick={() => {
                  props.closeAllDropdownsMobile();
                }}
              >
                {navElementMobile.displayName}
              </div>
            </Link>
            {navElementMobile.dropdown.length > 0 && (
              <button
                className={classes.divArrow}
                onClick={() => {
                  props.openDropdown(navElementMobile.page, true);
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default NavbarMobile;
