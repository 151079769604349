import React, { useState } from 'react';
import classes from './HighLightBox.module.css';
import { handleLinkClick } from '../../utils/markdown';
import { markdown } from '../../utils/markdown.js';

const HighLightBox = props => {
  const [hover, setHover] = useState(false);
  // //   --clr_orange: #f29727;
  // // --clr_teal: #22a699;
  // // --clr_yellow: #f2be23;
  // // --clr_red: #f24c3d;
  // // --clr_grey: #aaa9a9;
  // // --clr_LightGrey: #d1d1d1;
  //==================================================================
  const textDiv = (
    <div
      className={`${classes.highLightTextBox}`}
      style={{
        textAlign: `${props.textPosition ? props.textPosition : 'center'} `,
        padding: `${props.textPadding ? props.textPadding : '2rem'} `,
        borderRadius: `${props.rT ? props.rT : props.r}`,
        color: `${props.textColor ? props.textColor : ''}`,
        backgroundColor: `${
          props.colorTextBox ? props.colorTextBox : '#e0e0e0'
        }`,
      }}
    >
      {markdown(props.text, true, '')}
      {/* {props.text} */}
    </div>
  );
  //==================================================================
  return (
    // <React.Fragment>
    <div
      className={`${classes.highLightBox} ${
        !props.roundedOff && classes.rounded
      } ${hover && classes.hover} `}
      style={{
        justifyContent: `${props.position}`,
        cursor: `${props.a ? 'Pointer' : ''}`,
        borderRadius: `${props.r ? props.r : '1rem'}`,
        padding: `${props.boxPadding ? props.boxPadding : ''} `,
        backgroundColor: `${props.colorBox ? props.colorBox : ''}`,
      }}
      id={`${props.id}`}
      onClick={() => {
        if (props.a) handleLinkClick(props.a);
      }}
      onMouseEnter={() => {
        props.a ? setHover(true) : setHover(false);
      }}
      onMouseLeave={() => {
        props.a ? setHover(false) : setHover(false);
      }}
    >
      {props.link && <a href={`${props.link}`}>{textDiv}</a>}
      {!props.link && textDiv}
    </div>
    // </React.Fragment>
  );
};

export default HighLightBox;
