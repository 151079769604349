/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import Header from './Header';
import Footer from './Footer';
import Content from './Content';
import Sidebar from './Sidebar';
import classes from './Page.module.css';

import { motion, AnimatePresence } from 'framer-motion';

const Page = props => {
  //==================================================================
  const divPageContentSidebar = document.getElementById(
    'divPageContentSidebar'
  );

  //==================================================================
  //==================================================================
  return (
    <div className={`${classes.page}`} id={'content-page'}>
      <Header></Header>
      <motion.div
        className={`${classes.pageContent} }`}
        id={'divPageContentSidebar'}
      >
        <Content
          content={props.content}
          currentPage={props.currentPage}
          dev={props.dev}
          toggleLoading={props.toggleLoading}
        ></Content>
        <AnimatePresence>
          {props.sidebarToggle && (
            <Sidebar
              currentPage={props.currentPage}
              data={props.data}
            ></Sidebar>
          )}
        </AnimatePresence>
      </motion.div>
      <Footer></Footer>
    </div>
  );
};

export default Page;
