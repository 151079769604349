//==================================================================
// // // Womo
import womo from './womo.md';
import womoAuswahl from './womoAuswahl.md';
import erstausstattung from './erstausstattung.md';
import campingGadgets from './campingGadgets.md';
import lkwFührerschein from './lkwFührerschein.md';
import wohnmobilÜberladen from './wohnmobilÜberladen.md';
import wohnmobilAnhänger from './wohnmobilAnhänger.md';
// import abfahrtskontrolle from './abfahrtskontrolle.md';
import abfahrtskontrolle from '../../../components/pages/Abfahrtskontrolle';
import anschnallpflicht from './anschnallpflicht.md';

//==================================================================
// // // // Sidebar
// import sidebar from './pageContent/sidebar/sidebar.md';
// import Paypal from '../../../components/sidebar/Paypal';
//==================================================================

export const contentObjectWomo = {
  //==================================================================
  womo: {
    page: ['womo', '/womo'],
    displayName: 'Womo',
    icon: '🚌',
    content: [{ type: 'md', content: womo }],
    date: { created: '01.08.23' },
    tags: ['wohnmobil', 'womo'],
  },
  womoAuswahl: {
    page: [
      'welches-wohnmobil-passt-zu-uns',
      '/womo/welches-wohnmobil-passt-zu-uns',
    ],
    displayName: 'Welches Womo passt zu uns',
    icon: '❓',
    content: [{ type: 'md', content: womoAuswahl }],
    date: { created: '06.01.24' },
    tags: ['wohnmobil', 'womo', 'ausstattung'],
  },
  erstausstattung: {
    page: ['wohnmobil-erstausstattung', '/womo/wohnmobil-erstausstattung'],
    displayName: 'Wohnmobil Erstausstattung',
    icon: '🎁',
    content: [{ type: 'md', content: erstausstattung }],
    date: { created: '10.01.24' },
    tags: ['wohnmobil', 'womo', 'ausstattung', 'gadgets'],
  },
  campingGadgets: {
    page: ['must-have-camping-gadgets', '/womo/must-have-camping-gadgets'],
    displayName: 'Must have Camping Gadgets',
    icon: '👉',
    content: [{ type: 'md', content: campingGadgets }],
    date: { created: '15.01.24' },
    tags: ['gadgets', 'ausstattung'],
  },
  lkwFührerschein: {
    page: [
      'was-kostet-ein-lkw-führerschein-2024',
      '/womo/was-kostet-ein-lkw-führerschein-2024',
    ],
    displayName: 'Was kostet ein LKW Führerschein in 2024',
    icon: '👉',
    content: [{ type: 'md', content: lkwFührerschein }],
    date: { created: '24.02.24' },
    tags: ['ausgaben', 'womo', 'wohnmobil'],
  },
  wohnmobilÜberladen: {
    page: [
      'wohnmobil-überladen-welche-strafe-droht-in-europa',
      '/womo/wohnmobil-überladen-welche-strafe-droht-in-europa',
    ],
    displayName: 'Wohnmobil überladen, welche Strafe droht in Europa',
    icon: '👉',
    content: [{ type: 'md', content: wohnmobilÜberladen }],
    date: { created: '05.03.24' },
    tags: ['womo', 'wohnmobil'],
  },
  wohnmobilAnhänger: {
    page: ['wohnmobilAnhänger', '/womo/wohnmobilAnhänger'],
    displayName: 'Wohnmobil-Anhänger',
    icon: '👉',
    content: [{ type: 'md', content: wohnmobilAnhänger }],
    date: { created: '15.03.24' },
    tags: ['womo', 'wohnmobil'],
  },
  abfahrtskontrolle: {
    page: ['abfahrtskontrolle', '/womo/abfahrtskontrolle'],
    displayName: 'Abfahrtskontrolle',
    icon: '👉',
    blogOmit: true,
    // offline: true,
    content: [{ type: 'react', content: abfahrtskontrolle }],
    date: { created: '20.08.24' },
    tags: ['womo', 'wohnmobil'],
  },
  anschnallpflicht: {
    page: [
      'anschnallpflicht-im-wohnmobil',
      '/womo/anschnallpflicht-im-wohnmobil',
    ],
    displayName: 'Anschnallpflicht',
    icon: '🧷',
    content: [{ type: 'md', content: anschnallpflicht }],
    date: { created: '14.09.24' },
    tags: ['womo', 'wohnmobil', 'Sicherheit'],
  },
};
