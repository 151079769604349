import React, { useState, useEffect } from 'react';
import classes from './AllBlogPosts.module.css';
// components
import BlogPostCard from '../ui/BlogPostCard.js';
// import Loading from '../ui/Loading';
import ArtikelSeparator from '../ui/ArtikelSeparator';
import MetaData from '../utils/MetaData';

// icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

// read contentObject and render markdown / react components, add to object,render to page
import {
  renderedContent,
  allBlogPosts,
  filteredByTags,
} from '../../utils/renderContentToObjectFilterBlogPosts.js';

// data
import { contentObject } from '../../data/contentObject';

// helper functions
import {
  returnRandomDataSet, //(allData, searchData, quantity)
  htmlText,
  checkIfHtmlContainsSearchText,
  getTagsArray,
} from '../../utils/searchEngine';

import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';
// react router
import { useSearchParams } from 'react-router-dom';
// TAGS
const tagsDefault = [
  'solar',
  'elektrik',
  'ausstattung',
  'stellplatz',
  'campingplatz',
  'maut',
  'elternzeit',
  'auszeit',
  'technik',
];

const AllBlogPosts = props => {
  const [renderedData, setRenderedData] = useState([]);
  const [renderedDataLoaded, setRenderedDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);

  const [tagsButtons, setTagsButtons] = useState(tagsDefault);
  const [tagsArray, setTagsArray] = useState([]);
  const [showTags, setShowTags] = useState(true);

  const [searchText, setSearchText] = useState('');
  let [omitFallBackEmptyListOnStartUp, setOmitFallBackEmptyListOnStartUp] =
    useState(false);
  const controlsBlogPostCard = useAnimationControls();
  const controlsArtikelSeparator = useAnimationControls();
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(`✅`, searchParams);
  //==================================================================
  const sortDataByCreatedAt = data => {
    return data.sort(function (y, x) {
      return x.date.unix - y.date.unix;
    });
  };
  //==================================================================
  const getRenderedContent = async () => {
    const dataRendered = await renderedContent(allBlogPosts(contentObject));
    //==================================================================
    // TODO cache data or redux or store
    //==================================================================
    setRenderedData(sortDataByCreatedAt(dataRendered));
    setData(dataRendered);
    const tagsFromContent = getTagsArray(dataRendered);
    setTagsButtons(tagsFromContent);
    setRenderedDataLoaded(true);
  };

  // fire on startup to render data
  useEffect(() => {
    getRenderedContent();
    setTimeout(() => {
      setOmitFallBackEmptyListOnStartUp(true);
    }, 1000);
  }, []);
  useEffect(() => {
    // after rendering check search params and update results
    setTagsArrayBySearchParams(searchParams);
  }, [renderedData]);
  //==================================================================
  // update data if tags are changing
  useEffect(() => {
    setData(filteredByTags(renderedData, tagsArray));
  }, [tagsArray]);

  useEffect(() => {
    setAdditionalData(returnRandomDataSet(renderedData, data, 20));
    controlsBlogPostCard.start({ scale: [0.2, 1], opacity: [0, 1] });
    controlsArtikelSeparator.start({ scale: [1, 1.06, 1] });
  }, [data]);
  //==================================================================

  // after loading page read html to search object
  useEffect(() => {
    loadHtmlSearchText();
  }, [renderedDataLoaded]);

  const loadHtmlSearchText = async () => {
    const renderedDataWithHtmlText = await htmlText(renderedData);
    setRenderedData(renderedDataWithHtmlText);
  };
  //==================================================================

  const handleTagClick = id => {
    const searchId = searchParams.get('wdtag');
    if (id === searchId) {
      setSearchParams();
      return;
    }
    setSearchParams('?wdtag=' + id);
  };
  const setTagsArrayBySearchParams = searchParams => {
    const id = searchParams.get('wdtag');
    if (id === undefined || id === null) {
      setTagsArray([]);
      return;
    }
    setTagsArray([id]);
  };
  useEffect(() => {
    setTagsArrayBySearchParams(searchParams);
  }, [searchParams]);
  //==================================================================
  const submitSearch = async text => {
    // reset states
    setTagsArray([]);
    setShowTags(false);
    // search input text to array separated by " "
    const searchTextArray = text.split(' ');
    // await search result until setData
    const dataSearchResult = await renderedData.filter(el =>
      checkIfHtmlContainsSearchText(searchTextArray, el.htmlText)
    );
    // in production is double set needed otherwise it doesn't work - i don't know why
    setData([...dataSearchResult]);
    setData(dataSearchResult);
  };
  //==================================================================
  const checkIfDataAll = (data, dataSearch, result1, result2) => {
    return data.length === dataSearch.length ? result1 : result2;
  };
  //==================================================================
  // const variantsDataList = { hidden: { opacity: 0 }, visible: { opacity: 1 } };
  const headerInitial = { y: -600 };
  const headerAnimate = { y: 0, transition: { delay: 0.2 } };
  const headerExit = { y: -800 };
  //==================================================================
  const abortQuery = () => {
    document.getElementById('search').value = '';
    setTagsArray([]);
    setSearchText('');
    setShowTags(true);
    setSearchParams('');
  };
  const querySearchText = () => {
    const search = document.getElementById('search').value;
    submitSearch(search.toLowerCase());
  };
  //==================================================================
  //==================================================================
  return (
    <div className={classes.blog}>
      <MetaData
        checked="omit"
        title="WomoDaddy Blogartikel, Wohnmobile, Camping, Elternzeit,Auszeit,Bloggen"
        description="Auf diesem Blog findest du alles über: Wohnmobile, Wohnmobiletechnik, Solar, Camping,Elternzeit,Auszeit,Bloggen,Elternzeitreise,Familienauszeit,Wohnmobilreisen"
      />
      <motion.div
        className={classes.blogHeaderSearch}
        initial={headerInitial}
        animate={headerAnimate}
        exit={headerExit}
      >
        <div className={`${classes.tagsIcon} ${classes.blogSearch}`}>
          <input
            id="search"
            type="text"
            placeholder="Hier Suchtext eingeben !"
            onChange={text => {
              setSearchText(text.target.value);
            }}
            onFocus={() => {
              document.onkeydown = e => {
                if (e.key === 'Enter') querySearchText();
                if (e.key === 'Escape') abortQuery();
              };
            }}
          />
          <div
            key=""
            className={`${classes.searchSubmit} ${classes.tagsIcon}`}
            onClick={() => {
              submitSearch(searchText.toLowerCase());
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </div>
        </div>
        <div
          className={`${classes.tags} ${classes.tagsIcon}`}
          onClick={abortQuery}
        >
          <FontAwesomeIcon icon={faCircleXmark} />
        </div>
      </motion.div>
      <AnimatePresence>
        {showTags && (
          <motion.div
            className={`${classes.blogTag} ${classes.blogBox}`}
            initial={headerInitial}
            animate={headerAnimate}
            exit={headerExit}
          >
            {tagsButtons.map(el => {
              return (
                <motion.div
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 1 }}
                  className={`${classes.tags} ${
                    tagsArray.some(t => t === el) && classes.tagChecked
                  }`}
                  id={el.toLocaleLowerCase().replace(' ', '')}
                  key={el}
                  onClick={() => {
                    handleTagClick(el);
                  }}
                >
                  {el.charAt(0).toUpperCase() + el.slice(1)}
                </motion.div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
      <ArtikelSeparator
        key="separator"
        iconShow={checkIfDataAll(data, renderedData, false, true)}
        icon={faStar}
        text={checkIfDataAll(
          data,
          renderedData,
          'Alle Blogartikel:',
          'Treffer zu deiner Suche:'
        )}
        integer={data.length}
        controls={controlsArtikelSeparator}
      ></ArtikelSeparator>
      <motion.div
        className={`${classes.blogGrid} ${classes.blogBox}`}
        key="mainList"
      >
        {data.length === 0 && omitFallBackEmptyListOnStartUp && (
          <BlogPostCard
            id={`noResults`}
            key={`noResults`}
            abortQuery={abortQuery}
            page={{ page: ['blog', '/blog'] }}
            content={
              <div>
                <h1>Leider kein Treffer 🤔</h1>
                <p>
                  <br />
                  Du kannst mehrere Tags gleichzeitig wählen !
                </p>
                <p>oder</p>
                <p>Mehrere Suchwörter eingeben !</p>
                <h2>
                  Bitte versuche es nochmal 🤞😃
                  <br />
                  &nbsp;
                </h2>
              </div>
            }
            styleUniform={true}
          ></BlogPostCard>
        )}
        {sortDataByCreatedAt(data).map((el, i) => {
          return (
            <BlogPostCard
              id={`blog-${el.page}`}
              key={`blog-${i}`}
              page={el}
              content={el.renderedContent}
              styleUniform={true}
              controls={controlsBlogPostCard}
            ></BlogPostCard>
          );
        })}
      </motion.div>
      {additionalData.length > 0 && (
        <ArtikelSeparator
          iconShow={true}
          icon={faRocket}
          text={'Weitere interessante Beiträge für dich...'}
        ></ArtikelSeparator>
      )}
      {additionalData.length > 0 && (
        <div
          className={`${classes.blogGrid} ${classes.blogBox}`}
          key={tagsArray}
        >
          {additionalData.map((el, i) => {
            return (
              <BlogPostCard
                id={`blogAdd-${el.page}`}
                page={el}
                key={`blogAdd-${i}`}
                content={el.renderedContent}
                styleUniform={true}
              ></BlogPostCard>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AllBlogPosts;
