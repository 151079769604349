export const setSlugId = () => {
  const h1 = document.getElementsByTagName('h1');
  const h2 = document.getElementsByTagName('h2');
  const h3 = document.getElementsByTagName('h3');
  const h4 = document.getElementsByTagName('h4');
  const h5 = document.getElementsByTagName('h5');
  const h6 = document.getElementsByTagName('h6');
  const htmlElements = [...h1, ...h2, ...h3, ...h4, ...h5, ...h6];
  // create id with slug
  htmlElements.map(el => {
    if (el.innerHTML.includes('ID::')) {
      el.id = el.innerHTML.split(' ').pop().replace('ID::', '');
      el.innerHTML = el.innerHTML.split(' ').slice(0, -1).join(' ');
    }
  });
};
export const scrollToContent = id => {
  // console.log('✅', id);
  const searchElement = document.getElementById(id);
  // console.log('✅', searchElement);
  if ((searchElement !== undefined) & (searchElement !== null)) {
    searchElement.scrollIntoView({
      behavior: 'smooth',
    });
  }
};
