const template = {
  gridTemplateColumns:
    'minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, 15rem)',
  tableHeader: { text: 'Header Text - This Heading', h: '3' },
  rowHeader: { row: ['head1', 'head2', 'head3'] },
  content: [
    { row: ['Row 1', 1, 2] },
    { row: ['Row 2', 1, 2] },
    { row: ['Row 3', 1, 2] },
  ],
  rowFooter: { row: ['', 'Sum', '12345'] },
};

export const wechselrichter = {
  gridTemplateColumns: 'minmax(min-content, auto) minmax(min-content, auto) ',
  tableHeader: {
    text: '',
    h: '3',
  },
  rowHeader: {
    row: ['Gerät', 'Leitung in Watt'],
  },
  content: [
    { row: ['E-Bike Ladegerät', '150-200'] },
    { row: ['Laptop Ladegerät', '100-200'] },
    { row: ['TV Gerät + Sat', '50-200'] },
    { row: ['Föhn', '1500-2000'] },
    { row: ['Wasserkocher', '1000-2500'] },
    { row: ['Kaffeemaschine', '1400-1500'] },
    { row: ['Mikrowelle', '800-1000'] },
    { row: ['Mini-Backofen', '2000-3000'] },
  ],
  // rowFooter: { row: ['', '', 'Summe', '4320 €', '2628 €', '1314 €'] },
};
