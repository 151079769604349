/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { nav } from '../../data/nav.js';
import classes from './Navbar.module.css';
import Dropdown from './Dropdown';
import NavbarMobile from './NavbarMobile';
import { Link } from 'react-router-dom';

// Store
import { useSnapshot } from 'valtio';
import { store } from '../../store/store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const snap = useSnapshot(store);

  const [menu, setMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [secondDropdown, setSecondDropdown] = useState(false);

  const [modalHide, setModalHide] = useState(true);
  const [timer, setTimer] = useState(true);

  const onSetDropdown = state => {
    setDropdown(state);
    setModalHide(false);
  };
  const toggleMenu = state => {
    if (state === undefined) {
      setModalHide(!menu);
      setMenu(!menu);
      return;
    }
    store.modal = !menu;
    setModalHide(menu);
    setMenu(state);
  };
  //==================================================================
  const onClickDropdown = page => {
    setDropdown(false);
  };
  //==================================================================
  // handle mobile
  const [dropdownMobile, setDropdownMobile] = useState([]);

  const toggleDropdown = (page, state) => {
    setDropdownMobile(dropdownMobile => {
      const index = dropdownMobile.findIndex(el => el.page === page);
      dropdownMobile[index].state = state === undefined ? false : state;
      return [...dropdownMobile];
    });
  };
  const closeAllDropdownsMobile = () => {
    setModalHide(true);
    store.modal = false;
    setDropdownMobile(dropdownMobile => {
      const newState = dropdownMobile.map(el => {
        el.state = false;
        return el;
      });
      return [...newState];
    });
    setMenu(false);
  };
  //==================================================================
  useEffect(() => {
    let dropdownArray = [];
    nav.dropdown.map(navElement => {
      Object.keys(navElement).map(key => {
        if (navElement.dropdown.length > 0) {
          if (dropdownArray.includes(navElement)) return;
          navElement.state = false;
          dropdownArray = [...dropdownArray, navElement];
        }
      });
      setDropdownMobile([...dropdownArray]);
    });
  }, []);

  //==================================================================
  const modalHandler = () => {
    setModalHide(true);
    store.modal = false;
    if (dropdown) setDropdown(false);
    if (menu) closeAllDropdownsMobile();
  };
  //==================================================================
  const navFilteredOfflineElements = navArray => {
    return navArray.filter(
      el => el.offline === false || el.offline === undefined
    );
  };
  //==================================================================
  const pageHeight = document.getElementById('appPage')?.scrollHeight
    ? document.getElementById('appPage').scrollHeight
    : 1000;
  //==================================================================

  //==================================================================
  return (
    <nav className={classes.nav}>
      <div className={classes.divHamburgerMenu}>
        <div
          className={classes.divHamburger}
          onClick={() => {
            toggleMenu(true);
          }}
        >
          <FontAwesomeIcon icon={faBars} />
        </div>
      </div>
      <div
        className={`${classes.modal} ${!dropdown && classes.modalMobile} 
          ${modalHide && classes.modalOff}`}
        onClick={modalHandler}
        // style={{ height: `${pageHeight}px` }}
      ></div>
      {/* navbar browser */}
      <ul className={`${classes.navbar}`}>
        {navFilteredOfflineElements(nav.dropdown).map(navElement => {
          return (
            <li
              key={uuid()}
              // key={navElement.page}
              id={navElement.page}
              className={`${classes.divNavElement} ${
                navElement.inProgress && classes.textCrossedOut
              }`}
              onMouseLeave={modalHandler}
              onClick={() => {
                setModalHide(true);
              }}
            >
              <Link
                to={`/${
                  navElement.inProgress === true
                    ? 'inProgress'
                    : navElement.page
                }`}
                rel="canonical"
                onMouseEnter={() => {
                  onSetDropdown(navElement.page);
                }}
              >
                <div
                  className={`${classes.main} ${classes.navElement}`}
                  onClick={() => {
                    onSetDropdown(navElement.page);
                    setDropdown(false);
                  }}
                >
                  {navElement.displayName}
                </div>
              </Link>
              <div
                onMouseLeave={() => {
                  if (timer) {
                    setModalHide(true);
                    setDropdown(false);
                  }
                }}
                className={`${classes.dropdown}  ${
                  dropdown === navElement.page &&
                  navElement.dropdown.length > 0 &&
                  classes.dropdownShow
                }`}
              >
                {
                  <Dropdown
                    dropdownElements={navFilteredOfflineElements(
                      navElement.dropdown
                    )}
                    onClickDropdown={onClickDropdown}
                  ></Dropdown>
                }
              </div>
            </li>
          );
        })}
      </ul>
      {/* //================================================================== */}
      {/* navbar mobile */}
      <NavbarMobile
        id={'navigation'}
        navFilteredOfflineElements={navFilteredOfflineElements(nav.dropdown)}
        menu={menu}
        toggleMenu={toggleMenu}
        nav={nav}
        openDropdown={toggleDropdown}
        pageHeight={pageHeight}
        closeAllDropdownsMobile={closeAllDropdownsMobile}
      ></NavbarMobile>
      {dropdownMobile.map((el, i) => {
        return (
          <NavbarMobile
            id={el.page}
            navFilteredOfflineElements={navFilteredOfflineElements(el.dropdown)}
            key={uuid()}
            // key={i}
            menu={el.state}
            toggleMenu={toggleMenu}
            nav={el}
            openDropdown={toggleDropdown}
            closeDropdown={toggleDropdown}
            closeAllDropdownsMobile={closeAllDropdownsMobile}
          ></NavbarMobile>
        );
      })}
    </nav>
  );
};

export default Navbar;
