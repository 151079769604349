import React from 'react';

const H1 = props => {
  // omitMeta and change h1 to h2 if blog post card
  const heading = text => {
    if (props.omitMeta) {
      return <h2 style={{ fontSize: '2.8rem' }}>{text}</h2>;
    } else {
      return <h1>{text}</h1>;
    }
  };
  return heading(props.text);
};

export default H1;
