import React, { useState } from 'react';
import classes from './Img.module.css';
import { handleLinkClick, disableIfCardComponent } from '../../utils/markdown';

const Img = props => {
  const [hover, setHover] = useState(false);
  const imgTag = (
    <img
      style={{
        maxHeight: `${props.maxHeight}`,
        minWidth: `${props.minWidth}`,
        maxWidth: `${props.maxWidth}`,
      }}
      src={`${process.env.PUBLIC_URL}${props.src}`}
      alt={props.alt}
      title={props.alt}
      className={`${classes.img}  ${!props.roundedOff && classes.rounded} ${
        props.multiply && classes.multiply
      }`}
    />
  );
  //==================================================================
  //==================================================================
  return (
    <React.Fragment>
      {disableIfCardComponent(props.omitMeta, props.preView) && (
        <div
          className={`${classes.imgBox} ${
            !props.roundedOff && classes.rounded
          } ${hover && classes.hover} `}
          style={{
            justifyContent: `${props.position}`,
            cursor: `${props.a ? 'Pointer' : ''}`,
          }}
          id={`${props.id}`}
          onClick={() => {
            if (props.a) handleLinkClick(props.a);
          }}
          onMouseEnter={() => {
            props.a ? setHover(true) : setHover(false);
          }}
          onMouseLeave={() => {
            props.a ? setHover(false) : setHover(false);
          }}
        >
          {props.link && <a href={`${props.link}`}>{imgTag}</a>}
          {!props.link && imgTag}
        </div>
      )}
    </React.Fragment>
  );
};

export default Img;
