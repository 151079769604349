const template = {
  gridTemplateColumns:
    'minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, 15rem)',
  tableHeader: { text: 'Header Text - This Heading', h: '3' },
  rowHeader: { row: ['head1', 'head2', 'head3'] },
  content: [
    { row: ['Row 1', 1, 2] },
    { row: ['Row 2', 1, 2] },
    { row: ['Row 3', 1, 2] },
  ],
  rowFooter: { row: ['', 'Sum', '12345'] },
};

export const einkommenVorElternzeit = {
  gridTemplateColumns:
    'minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, 15rem)',
  tableHeader: {
    text: '',
    h: '3',
  },
  rowHeader: {
    row: [
      'Name',
      'Brutto Einkommen',
      'Steuerklasse',
      'Netto Einkommen',
      'Basis Elterngeld',
      'Elterngeld Plus',
    ],
  },
  content: [
    { row: ['Frau Mustermann', '3000 €', '5', '1720 €', '1028 €', '514 €'] },
    { row: ['Herr Mustermann', '3500 €', '3', '2600 €', '1600 €', '800 €'] },
  ],
  rowFooter: { row: ['', '', 'Summe', '4320 €', '2628 €', '1314 €'] },
};

export const einnahmen = {
  gridTemplateColumns: 'minmax(min-content, auto) minmax(min-content, auto) ',
  tableHeader: {
    text: '',
    h: '3',
  },
  rowHeader: {
    row: ['Einnahmen', 'Betrag'],
  },
  content: [
    { row: ['Elterngeld Mutter', '1028 €'] },
    { row: ['Elterngeld Vater', '1600 €'] },
    { row: ['Kindergeld 1', '250 €'] },
    { row: ['Kindergeld 2', '250 €'] },
    { row: ['Einkommen während der Elternzeit', '0 €'] },
  ],
  rowFooter: { row: ['Summe', '3128'] },
};

export const ausgaben = {
  gridTemplateColumns:
    ' minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto)',
  tableHeader: {
    text: '',
    h: '3',
  },
  rowHeader: {
    row: ['Ausgabe', 'Intervall', 'Betrag', 'Summe'],
  },
  content: [
    { row: ['Miete', '12', '1200 €', '14400 €/Jahr'] },
    { row: ['Versicherungen', '1', '500 €', '500 €/Jahr'] },
    { row: ['Womo Versicherung', '1', '400 €', '400 €/Jahr'] },
    { row: ['Womo Steuer', '1', '500 €', '500 €/Jahr'] },
    { row: ['Womo Tüv/AU/Gas', '0.5', '210 €', '105 €/Jahr'] },
    { row: ['Auto Versicherung', '1', '250 €', '250 €/Jahr'] },
    { row: ['Auto Steuer', '1', '150 €', '150 €/Jahr'] },
    { row: ['Auto Tüv/AU', '0.5', '140 €', '70 €/Jahr'] },
    { row: ['Sonstiges (Handy, Netflix, usw.)', '12', '50 €', '600 €/Jahr'] },
    { row: ['Einkaufen', '12', '450 €', '5400 €/Jahr'] },
    { row: ['Campingplatz', '12', '450 €', '5400 €/Jahr'] },
    { row: ['Kraftstoff', '12', '300 €', '3600 €/Jahr'] },
    { row: ['Sonstiges (Reparaturen, usw.)', '12', '1500 €', '1500 €/Jahr'] },
    { row: ['', '', 'Summe', '32875 €/Jahr'] },
  ],
  rowFooter: { row: ['', '', 'Summe', '2740 €/Monat'] },
};

export const vergleich = {
  gridTemplateColumns:
    'minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto)  ',
  tableHeader: {
    text: '',
    h: '3',
  },
  rowHeader: {
    row: ['', 'Variante 1', 'Variante 2', 'Variante 3'],
  },
  content: [
    { row: ['Dauer', '6 Monate', '12 Monate', '24 Monate'] },
    { row: ['Basiselterngeld (Mutter *)', '2 Monate', '2 Monate', '2 Monate'] },
    { row: ['Elterngeld Mutter', '6x Basiselterngeld', 'ohne', 'ohne'] },
    {
      row: [
        'Elterngeld Vater',
        '6x Basiselterngeld',
        '12x Basiselterngeld',
        '24x Basiselterngeld',
      ],
    },
    { row: ['Kindergeld (2 Kinder)', '500 €', '500 €', '500 €'] },
    { row: ['Elterngeld Mutter', '1028 €', '0 €', '0 €'] },
    { row: ['Elterngeld Vater', '1600  €', '1600  €', '800 €'] },
    { row: ['Einkommen während der Elternzeit', '0 €', '1000 €', '1000 €'] },
  ],
  rowFooter: {
    row: ['Summe der monatlichen Einnahmen', '3128 €', '3100 €', '2300 €'],
  },
};

export const vergleichEinnahmenAusgaben = {
  gridTemplateColumns:
    'minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, 15rem)',
  tableHeader: {
    text: '',
    h: '3',
  },
  rowHeader: { row: ['', 'Variante 1', 'Variante 2', 'Variante 3'] },
  content: [
    { row: ['Summe der monatlichen Einnahmen', '3128 €', '3100 €', '2300 €'] },
    { row: ['Ausgaben', '2740 €', '2740 €', '2740 €'] },
  ],
  rowFooter: { row: ['Differenz', '388 €', '360 €', '-440 €'] },
};
