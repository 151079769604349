import notion from './notion.md';
// import story02 from './02_todo.md';
// import story03 from './03_traumErfüllen.md';
// import story04 from './04_reiseroute.md';
//==================================================================
export const contentObjectSocialMedia = {
  //==================================================================
  // // // Social Media
  notion: {
    page: [
      'notion-alle-infos-an-einem-Platz',
      '/social-media/notion-alle-infos-an-einem-Platz',
    ],
    displayName: 'Notion alle Infos an einem Platz',
    icon: '😍',
    sidebarOff: false,
    content: [{ type: 'md', content: notion }],
    date: { created: '02.08.24' },
    sidebar: [],
    tags: ['social-media'],
  },
};
