import React, { useState, useEffect } from 'react';
import {
  MapContainer,
  LayersControl,
  LayerGroup,
  TileLayer,
  Marker,
  Popup,
  useMap,
} from 'react-leaflet';
import classes from './LeafletMyPos.module.css';
import uuid from 'react-uuid';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import 'react-leaflet-fullscreen/styles.css';
//==================================================================
import MarkerComponent from './Marker';
import FetchCSVData from '../../utils/FetchCSVData';
//==================================================================
function LeafletMyPos(props) {
  //==================================================================
  const initialPosition = [49.8, 8.54];
  const initialZoom = 5;
  //==================================================================
  const createClusterCustomIcon = function (cluster) {
    var markers = cluster.getAllChildMarkers();
    var n = 0;
    for (var i = 0; i < markers.length; i++) {
      n = markers.length;
    }
    var small = n < 999;
    var cssSize = small ? classes.cssSmall : classes.cssLarge;
    var size = small ? 40 : 60;
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: `${cssSize} ${classes.customMarkerCluster}`,
      iconSize: L.point(size, size, true),
    });
  };
  //==================================================================
  // handle sheet data
  const [data, setData] = useState([]);
  const fetchedData = FetchCSVData(
    'https://docs.google.com/spreadsheets/d/1k5djrb-kBAgG3bSRp1A1YGgVVocarCbuEm5_hSA4MTk/pub?gid=0&single=true&output=csv'
  );
  useEffect(() => {
    setData(fetchedData);
  }, [fetchedData]);
  //==================================================================
  const geoStrCleanUp = str => {
    if (str) return str.replace(/"/gi, '').trim();
  };
  const currentPosition = fetchedData[fetchedData?.length - 1];
  console.log(`😍`, fetchedData);
  console.log(`✅`, currentPosition?.name);
  console.log(`✅`, geoStrCleanUp(currentPosition?.long));
  console.log(`✅`, geoStrCleanUp(currentPosition?.lat));
  //==================================================================
  return (
    <>
      <MapContainer
        id="map"
        zoom={initialZoom}
        center={initialPosition}
        className={classes.leafletContainer}
        scrollWheelZoom={true}
        attributionControl={false} //leaflet logo
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Open Street Map">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Google Maps Satellite">
            <LayerGroup>
              <TileLayer
                attribution="Google Maps Satellite"
                url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                maxZoom={20}
                subdomains={['mt1', 'mt2', 'mt3']}
              />
              <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
            </LayerGroup>
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Google Maps Street">
            <TileLayer
              attribution="Google Maps"
              url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        {
          <MarkerClusterGroup
            chunkedLoading
            // onClick={e => console.log('onClick', e)}
            iconCreateFunction={createClusterCustomIcon}
            maxClusterRadius={150}
            disableClusteringAtZoom={9}
            spiderfyOnMaxZoom={false}
            zoomToBoundsOnClick={true}
            animate={true}
            polygonOptions={{
              fillColor: '#e2e2e288',
              color: '#e7534e',
              weight: 5,
              opacity: 1,
              fillOpacity: 0.8,
            }}
            showCoverageOnHover={true}
          >
            {props.data.map(el => (
              <MarkerComponent
                obj={el}
                key={el.id}
                zoomLevel={3}
                mapProps={props}
              ></MarkerComponent>
            ))}
          </MarkerClusterGroup>
        }
      </MapContainer>
    </>
  );
}

export default LeafletMyPos;
