//==================================================================
// // // Sidebar
import sidebar from './sidebar.md';
import Paypal from '../../../components/sidebar/Paypal';
import LinkList from '../../../components/sidebar/LinkList';
import GoodVibes from '../../../components/sidebar/GoodVibes';
import Dice from '../../../components/sidebar/Dice';
import Separator from '../../../components/sidebar/Separator';

export const sidebarMd = { type: 'md', content: sidebar };

export const paypal = { type: 'react', content: Paypal };

export const mostRead = {
  type: 'react',
  content: LinkList,
  addProps: {
    heading: 'Meist gelesene Blogartikel',
    links: [
      {
        name: 'Unser Womo-Truck',
        icon: '🚒',
        link: '/blog/unser-wohnmobil-truck',
      },
      { name: 'Elterngeld', icon: '💲', link: '/auszeit/elterngeld' },
      { name: 'Elternzeit', icon: '⏱', link: '/auszeit/elternzeit' },
      {
        name: 'Blogger werden',
        icon: '🎁',
        link: '/blog/wie-kann-ich-blogger-werden',
      },
    ],
  },
};

export const blogSuggestionDefault = {
  type: 'react',
  content: LinkList,
  addProps: {
    heading: 'Interessante Beiträge für Dich !',
    links: [],
  },
};

export const goodVibes = {
  type: 'react',
  content: GoodVibes,
};

export const dice = {
  type: 'react',
  content: Dice,
};

export const separator = { type: 'react', content: Separator };
