import React from 'react';
// react router
import { Link as RouterLink } from 'react-router-dom';

const Link = props => {
  //==================================================================
  return (
    <RouterLink to={props.l} title={props.n}>
      {props.n}
    </RouterLink>
  );
};
export default Link;
