//==================================================================
// // // Technik
import technik from './technik.md';
import wechselrichter from './wechselrichter.md';

export const contentObjectTechnik = {
  //==================================================================
  technik: {
    page: ['technik', '/technik'],
    displayName: 'Wohnmobil Technik',
    icon: '🚎',
    content: [{ type: 'md', content: technik }],
    date: { created: '01.08.23' },
    tags: ['technik'],
  },
  wechselrichter: {
    page: ['wechselrichter', '/technik/wechselrichter'],
    displayName: 'Wechselrichter',
    icon: '🚩',
    content: [{ type: 'md', content: wechselrichter }],
    date: { created: '19.10.23' },
    tags: ['technik'],
  },
};
