import React from 'react';
import classes from './Header.module.css';
import Navbar from './Navbar';
import Social from '../ui/Social';
import logo from '../../assets/img/logoSchriftWithe.png';
import logoTriangle from '../../assets/img/logoTriangle.png';
import { motion } from 'framer-motion';

import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className={classes.header}>
      <Link
        className={`${classes.divLogo} ${classes.logoImgA}`}
        rel="canonical"
        to={`/home`}
      >
        <motion.img
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.3,
            delay: 0.5,
            ease: [0, 0.7, 0.2, 1],
            scale: {
              type: 'spring',
              damping: 5,
              stiffness: 100,
              restDelta: 0.001,
            },
          }}
          src={logo}
          alt="WomoDaddy"
        />
      </Link>
      <div className={classes.divNav}>
        <motion.div
          initial={{ y: -150 }}
          animate={{
            y: 0,
            transition: {
              type: 'spring',
              ease: 'linear',
              duration: 0.8,
              delay: 0.2,
            },
          }}
        >
          <Navbar></Navbar>
        </motion.div>
      </div>
      <div className={classes.divIcon}>
        <Social></Social>
      </div>
    </header>
  );
};

export default Header;
