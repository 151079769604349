/* eslint-disable react/prop-types */
import React from 'react';
import classes from './Dropdown.module.css';
import { Link } from 'react-router-dom';

const Dropdown = props => {
  return (
    <ul className={classes.dropdown}>
      {/* // eslint-disable-next-line react/prop-types, react/prop-types */}
      {props.dropdownElements.map(dropdownElement => (
        <li
          key={dropdownElement.page}
          onClick={() => {
            props.onClickDropdown();
          }}
          className={`${classes.dropdownElement} ${
            dropdownElement.inProgress && classes.textCrossedOut
          }`}
        >
          <Link
            to={`/${
              dropdownElement.inProgress === true
                ? 'inProgress'
                : dropdownElement.page
            }`}
          >
            <div className={`${classes.dropdownElement} `}>
              {dropdownElement.displayName}
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Dropdown;
