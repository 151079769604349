import React from 'react';
import classes from './GoodVibes.module.css';

import { randomNumber } from '../../utils/searchEngine';

import {
  goodVibePhrase,
  goodVibePictures,
} from '../../data/pageContent/sidebar/goodVibes.js';

const GoodVibes = () => {
  return (
    <div className={classes.linkBox}>
      <p className={classes.heading}>Weisheit des Tages:</p>
      <p>{goodVibePhrase[randomNumber(goodVibePhrase)]}</p>
    </div>
  );
};

export default GoodVibes;
