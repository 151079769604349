import * as React from 'react';
import classes from './Chart.module.css';
import uuid from 'react-uuid';

import {
  einkommenVorElternzeit,
  einnahmen,
  ausgaben,
  vergleich,
  vergleichEinnahmenAusgaben,
} from '../../data/pageContent/auszeit/variantenFinanzen/variantenFinanzenChart.js';

import { wechselrichter } from '../../data/pageContent/technik/technikChart';
import { fahrzeugKategorie } from '../../data/pageContent/womo/womoAuswahl1';

const Chart = props => {
  let chart;
  switch (props.chartName) {
    case 'einkommenVorElternzeit':
      chart = einkommenVorElternzeit;
      break;
    case 'einnahmen':
      chart = einnahmen;
      break;
    case 'ausgaben':
      chart = ausgaben;
      break;
    case 'vergleich':
      chart = vergleich;
      break;
    case 'vergleichEinnahmenAusgaben':
      chart = vergleichEinnahmenAusgaben;
      break;
    case 'wechselrichter':
      chart = wechselrichter;
      break;
    case 'fahrzeugKategorie':
      chart = fahrzeugKategorie;
      break;

    default:
      chart = defaultChart;
  }
  //
  const tableHeader = int => {
    switch (int) {
      case '1':
        return <h1>{chart.tableHeader?.text}</h1>;
      case '2':
        return <h2>{chart.tableHeader?.text}</h2>;
      case '4':
        return <h4>{chart.tableHeader?.text}</h4>;
      default:
        return <h3>{chart.tableHeader?.text}</h3>;
    }
  };
  //

  //==================================================================
  return (
    <div className={classes.chartBox} id={props.id}>
      {chart.tableHeader?.text && tableHeader(chart.tableHeader?.h)}

      <div
        className={classes.chart}
        style={{
          gridTemplateColumns: `${chart.gridTemplateColumns}`,
        }}
      >
        {/* //================================================================== */}
        {chart.rowHeader?.row.map(el => (
          <header
            key={uuid()}
            className={`${classes.item} ${classes.itemHead}`}
          >
            {el}
          </header>
        ))}

        {/* //================================================================== */}
        {chart.content?.map(e =>
          e.row.map(el => (
            <div key={uuid()} className={`${classes.item} `}>
              {el}
            </div>
          ))
        )}

        {/* //================================================================== */}
        {chart.rowFooter?.row.map(el => (
          <div
            key={uuid()}
            className={`${classes.item} ${classes.itemFooter} `}
          >
            {el}
          </div>
        ))}
        {/* //================================================================== */}
      </div>
    </div>
  );
};

export default Chart;

const defaultChart = {
  gridTemplateColumns:
    'minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, 15rem)',
  tableHeader: { text: '', h: '3' },
  rowHeader: { row: ['loading', '...', '...'] },
  content: [
    { row: ['...', ' ', ''] },
    { row: ['...', ' ', ''] },
    { row: ['...', ' ', ''] },
  ],
  // rowFooter: { row: ['', 'Sum', '12345'] },    // possible example
};
