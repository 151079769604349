// handle render
import { renderContent } from './renderContent.js';

//==================================================================
// read contentObject and render markdown / react components,
// add rendered content to contentObject
// render content to page
// mainly used in allBlogPosts
//==================================================================
// add rendered content to objects, to show on blog page

export const renderedContent = async data => {
  if (data === undefined) return;
  return await Promise.all(
    Object.entries(data).map(async obj => {
      const renderElement = await renderContent({
        content: obj[1].content,
        omitMeta: true, // omit meta on blog overview , also used to for dateBox
        date: obj[1].date,
      });
      obj[1].renderedContent = renderElement;
      return obj[1];
    })
  );
};

export const allBlogPosts = data => {
  const filteredBlogOmit = Object.entries(data).filter(
    el => el[1].blogOmit === false || el[1].blogOmit === undefined
  );

  return filteredBlogOmit.map(el => el[1]);
};

export const filteredByTags = (data, tagsArray) => {
  if (tagsArray.length === 0) {
    return data;
  }
  const tagsArrayLowercase = tagsArray.map(el => el.toLowerCase());
  return data.filter(el =>
    // blogPost tags toLowercase, than filter against array of tags toLowercase
    el.tags
      .map(el => el.toLowerCase())
      .some(
        tagEl =>
          tagEl ===
          tagsArrayLowercase.filter(tagsArrayEl => tagsArrayEl === tagEl)[0]
      )
  );
};
