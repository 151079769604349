import React, { useState, useRef, useEffect, createContext } from 'react';
import {
  // Map,
  MapContainer,
  LayersControl,
  LayerGroup,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
} from 'react-leaflet';
import classes from './LeafletMap.module.css';
import uuid from 'react-uuid';

//==================================================================
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';
//==================================================================
import MarkerComponent from './Marker';
import SearchControl from './SearchControl';
// import { OpenStreetMapProvider } from 'react-leaflet-geosearch';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
// // import 'leaflet/dist/leaflet.css';
// import { useMap } from 'react-leaflet';
// import markerIcon from '../../assets/mapImg/icon/marker.svg';
//==================================================================
//==================================================================
//==================================================================
const ZoomEventHandlers = ({ handleZoomEnd }) => {
  useMapEvent('zoomend', handleZoomEnd);
  return null;
};
// const { BaseLayer } = LayersControl;

//==================================================================
function LeafletMap(props) {
  //==================================================================
  const initialPosition = [49.8, 8.54];
  const initialZoom = 5;
  const [zoomLevel, setZoomLevel] = useState(initialZoom);
  const handleZoomEnd = e => {
    setZoomLevel(e.target.getZoom());
    console.log('Map zoom level:', e.target.getZoom());
  };
  const prov = new OpenStreetMapProvider();
  const [markers, setMarkers] = useState([]);

  const handleAddMarker = marker => {
    let tmp = markers;
    tmp.push(marker);
    setMarkers(tmp);
    console.log(marker, markers);
  };
  //==================================================================

  //==================================================================
  const createClusterCustomIcon = function (cluster) {
    var markers = cluster.getAllChildMarkers();
    var n = 0;
    for (var i = 0; i < markers.length; i++) {
      n = markers.length;
    }
    var small = n < 999;
    var cssSize = small ? classes.cssSmall : classes.cssLarge;
    var size = small ? 40 : 60;
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: `${cssSize} ${classes.customMarkerCluster}`,
      iconSize: L.point(size, size, true),
    });
  };
  //==================================================================
  // what does this do ???
  const leafletPane = document.getElementsByClassName('leaflet-pane');
  const leafletControl = document.getElementsByClassName(
    'leaflet-control-container'
  );
  //==================================================================
  //==================================================================
  return (
    <>
      <MapContainer
        id="map"
        zoom={initialZoom}
        center={initialPosition}
        className={classes.leafletContainer}
        scrollWheelZoom={true}
        attributionControl={false} //leaflet logo
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Google Maps Satellite">
            <LayerGroup>
              <TileLayer
                attribution="Google Maps Satellite"
                url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                maxZoom={20}
                subdomains={['mt1', 'mt2', 'mt3']}
              />
              <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
            </LayerGroup>
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Google Maps Street">
            <TileLayer
              attribution="Google Maps"
              url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Open Street Map">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        {
          <MarkerClusterGroup
            chunkedLoading
            // onClick={e => console.log('onClick', e)}
            iconCreateFunction={createClusterCustomIcon}
            maxClusterRadius={150}
            disableClusteringAtZoom={9}
            spiderfyOnMaxZoom={false}
            zoomToBoundsOnClick={true}
            animate={true}
            polygonOptions={{
              fillColor: '#e2e2e288',
              color: '#e7534e',
              weight: 5,
              opacity: 1,
              fillOpacity: 0.8,
            }}
            showCoverageOnHover={true}
          >
            {props.data.map(el => (
              <MarkerComponent
                obj={el}
                key={el.id}
                zoomLevel={zoomLevel}
                mapProps={props}
              ></MarkerComponent>
            ))}
          </MarkerClusterGroup>
        }
        <SearchControl
          provider={prov}
          showMarker={true}
          showPopup={false}
          popupFormat={({ query, result }) => result.label}
          retainZoomLevel={false}
          animateZoom={true}
          autoClose={false}
          searchLabel={'Enter address, please'}
          keepResult={true}
          addMarker={handleAddMarker}
        />
        <FullscreenControl />
      </MapContainer>
      {useEffect(() => {
        markers.map((marker, index) => (
          <Marker key={`marker-${index}`} position={marker.position}>
            <Popup>This is {`marker-${index}`}</Popup>
          </Marker>
        ));
      }, [markers])}
    </>
  );
}

export default LeafletMap;

// create external function component to handle data
//==================================================================
// // // handle data get first 50 items of list
// // const names = el => {
// //   var i = 0;
// //   var a = [];
// //   console.log(
// //     `✅`,
// //     el.map(e => {
// //       i++;
// //       if (i < 51) a = [...a, e.name];
// //     })
// //   );
// //   console.log(`✅`, a);
// // };
// // names(dataAbove11m);
//==================================================================
// // const searchItems = A50;
// // const results = result1;
// // // var leftOfer;
// // // var completed;

// // const checkIfExists = (search, results) => {
// //   const resArraySearchString = [...new Set(results.map(el => el.title))];
// //   const needToSearch = search.filter(
// //     el => !resArraySearchString.some(e => e == el)
// //   );
// //   // console.log(`✅ NEED`, needToSearch);
// //   // console.log(
// //   //   `✅`,
// //   //   results.map(el => el.address)
// //   // );
// // };
// // checkIfExists(searchItems, results);
