import { useMap } from 'react-leaflet';
import { GeoSearchControl } from 'leaflet-geosearch';
import React, { useEffect } from 'react';
import L from 'leaflet';

// Get coordinates
// https://react-leaflet.js.org/docs/example-external-state/

const SearchControl = props => {
  const map = useMap();
  // map.removeControl(map.attributionControl);
  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider: props.provider,
      style: 'bar',
      marker: {
        icon: new L.icon({
          iconUrl:
            'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
          iconSize: [25, 41],
          iconAnchor: [10, 41],
          popupAnchor: [2, -40],
        }),
      },
      ...props,
    });
    map.addControl(searchControl);

    map.on('geosearch/showlocation', function (e) {
      let marker = {
        name: e.location.label,
        position: [e.location.x, e.location.y],
      };
      // console.log('x', e);
      props.addMarker(marker);
    });
    //==================================================================
    // console.log(map);
    // map.on('change', e => {
    //   // map.on('events/input', e => {
    //   console.log('change', e);
    // });
    // map.onAdd('geosearch/showlocation', function (e) {
    // console.log('added');
    // });
    // _onInputChange;
    //==================================================================
    return () => map.removeControl(searchControl);
  }, [map, props]);

  return null;
};
export default SearchControl;

// import { useEffect, useState } from 'react';
// import { useMap } from 'react-leaflet';
// import { GeoSearchControl } from 'leaflet-geosearch';
// import L from 'leaflet';

// const SearchControl = props => {
//   const map = useMap();

//   useEffect(() => {
//     //==================================================================
//     const searchControl = new GeoSearchControl({
//       provider: props.provider,
//       style: 'bar',
//       showMarker: true,
//       showPopup: false,
//       marker: {
//         icon: new L.icon({
//           iconUrl:
//             'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
//           iconSize: [25, 41],
//           iconAnchor: [10, 41],
//           popupAnchor: [2, -40],
//         }),
//         maxMarkers: 1,
//       },
//       // popupFormat: ({ query, result }) => {
//       //   console.log(`✅`, result);
//       //   return result.label;
//       // },
//       // retainZoomLevel: true,
//     });
//     map.addControl(searchControl);
//     // map.on('geosearch/showlocation', function (e) {
//     //   console.log(`Geo`, e.marker._latlng);
//     //   console.log(`Marker`, e.marker);
//     // });
//     map.on('geosearch/showlocation', function (e) {
//       let marker = {
//         name: e.location.label,
//         position: [e.location.x, e.location.y],
//       };
//       // console.log(e.location);
//       props.addMarker(marker);
//     });
//     return () => map.removeControl(searchControl);
//     //==================================================================
//     //==================================================================
//     // // first nice result
//     // // const searchControl = new GeoSearchControl({
//     // //   provider: props.provider,
//     // //   // style: 'bar',
//     // //   marker: {
//     // //     icon: new L.icon({
//     // //       iconUrl:
//     // //         'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
//     // //       iconSize: [25, 41],
//     // //       iconAnchor: [10, 41],
//     // //       popupAnchor: [2, -40],
//     // //     }),
//     // //   },
//     // //   ...props,
//     // // });
//     // // map.addControl(searchControl);
//     // // return () => map.removeControl(searchControl);
//     //==================================================================
//   }, [map, props]);

//   return null;
// };
// export default SearchControl;
