export const goodVibePhrase = [
  'Reisen ist Inspiration ! Reisen ist Leben ! Reisen ist deine Luft zum Atmen !',
  'Runter vom Sofa und rein ins Abenteuer',
  'Das leben ist zu kurz für morgen !',
  'Jede große Reise startet mit dem ersten Schritt !',
  'Man reist nicht, um anzukommen, sondern um zu reisen.“ – Johann Wolfgang von Goethe.',
  '„Das Reisen macht dich sprachlos und verwandelt dich dann in einen Geschichtenerzähler.“ – Ibn Battuta.',
  '„In 20 Jahren wirst du mehr enttäuscht sein von den Dingen, die du nicht getan hast, als von denen, die du getan hast.“ – Mark Twain',
  '„Wohin du auch gehst, geh mit deinem ganzen Herzen.“ – Konfuzius',
  '„Was wäre das Leben, hätten wir nicht den Mut, etwas zu riskieren?“ – Vincent can Gogh',
  '„Das Glück besteht darin, seine Ziele zu erreichen, nicht darin, sie zu besitzen.“ – Ralph Waldo Emerson',
  '„Wege entstehen dadurch, dass man sie geht.“ – Erich Kästner',
  '„Der kürzeste Weg zu dir selbst führt einmal um die Welt.“ – Richard Hoffmann',
  '„Die Welt ist ein Buch, und diejenigen, die nicht reisen, lesen nur eine Seite.“ – Augustinus Aurelius',
  '„Das Reisen ist eine wunderbare Art der Selbstbildung.“ – Gustave Flaubert',
  '„Reisen ist die schönste Art zu lernen.“ – Stefan Schirmer',
  '„Sammle Momente, nicht Dinge.“ – Unbekannt',
  '„Wer reist, kann viel erzählen.“ – Johann Wolfgang von Goethe',
  '„Reisen tut immer gut.“ – Voltaire',
  '„Reisen bedeutet Freiheit.“ – Hans Christian Andersen',
];
const path = '/img/un/';
export const goodVibePictures = [
  `${path}aziz-acharki-bgovxZpRgaE-unsplash.jpg`,
  `${path}beach-water-sand-sky-sunshine-car-971394-pxhere.com.jpg`,
  `${path}benjamin-davies-FiZTaNTj2Ak-unsplash.jpg`,
  `${path}cristian-escobar-abkEAOjnY0s-unsplash.jpg`,
  `${path}dave-contreras-R1PUDOAhb5Q-unsplash.jpg`,
  `${path}dev-asangbam-L00RzDytoZA-unsplash.jpg`,
  `${path}frida-aguilar-estrada-rYWKAgO7jQg-unsplash.jpg`,
  `${path}guille-pozzi-y1wVavuxZtE-unsplash.jpg`,
  `${path}ian-stauffer-uftqFbfWGFY-unsplash.jpg`,
  `${path}jack-b-sXXLJ4gdKYo-unsplash.jpg`,
  `${path}jeremy-thomas-FO7bKvgETgQ-unsplash.jpg`,
  `${path}kazuend-sN1cOV7wfCM-unsplash.jpg`,
  `${path}keegan-houser - Q_t4SCN8c4-unsplash.jpg`,
  `${path}marc-najera-Cj2d2IUEPDM-unsplash.jpg`,
  `${path}matteo-di-iorio-VD-Vjc8VmRA-unsplash.jpg`,
  `${path}melissa-askew-8n00CqwnqO8-unsplash.jpg`,
  `${path}mohamed-nohassi-odxB5oIG_iA-unsplash.jpg`,
  `${path}ocean-silhouette-sunset-morning-love-dance-940568-pxhere.com.jpg`,
  `${path}peter-conlan-LEgwEaBVGMo-unsplash.jpg`,
  `${path}sebastien-gabriel - IMlv9Jlb24-unsplash.jpg`,
  `${path}william-farlow-IevaZPwq0mw-unsplash.jpg`,
  `${path}xan-griffin-eA2t5EvcxU4-unsplash.jpg`,
  `${path}zac-durant-_6HzPU9Hyfg-unsplash.jpg`,
];
