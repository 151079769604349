import React from 'react';
import classes from './Social.module.css';
import tiktok from '../../assets/icon/tiktok.png';
import instagram from '../../assets/icon/instagram.png';
import fb from '../../assets/icon/facebook.png';
import yt from '../../assets/icon/youtube.png';

const Social = () => {
  return (
    <div className={classes.divIcon}>
      <div className={classes.divLink}>
        <a
          href="https://www.facebook.com/profile.php?id=61562801646428"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <img
            src={fb}
            alt="facebook"
            className={`${classes.icon} ${classes.fbIcon}`}
          />
        </a>
        <div className={`${classes.blurredBackground} ${classes.fb}`}></div>
      </div>
      <div className={classes.divLink}>
        <a
          href="https://www.youtube.com/channel/UCNmFNysXs2X6wOw4UCdWNrQ"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <img src={yt} alt="you tube" className={classes.icon} />
        </a>
        <div className={`${classes.blurredBackground} ${classes.yt}`}></div>
      </div>
      <div className={classes.divLink}>
        <a
          href="https://www.tiktok.com/@womodaddy"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <img src={tiktok} alt="tiktok" className={classes.icon} />
        </a>
        <div className={`${classes.blurredBackground} ${classes.tt}`}></div>
      </div>
      <div className={classes.divLink}>
        <a
          href="https://www.instagram.com/womodaddy/"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <img src={instagram} alt="instagram" className={classes.icon} />
        </a>
        <div className={`${classes.blurredBackground} ${classes.tt}`}></div>
      </div>
    </div>
  );
};

export default Social;
