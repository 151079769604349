// Store
import { store } from '../store/store';

const resetLoading = time => {
  setTimeout(
    () => {
      store.loading = false;
    },
    time ? time : 500
  );
};

// // // example
// toggleLoading({ page, lastPage: snap.page, timer: 300 state:true});
// disable and maybe DELETE

export const toggleLoading = ({ page, lastPage, timer, state }) => {
  if (state) store.loading = state;
  store.page = page;
  if (lastPage === 'blog') {
    store.loading = true;
  }
  resetLoading(timer);
};
