import React, { useState } from 'react';
import classes from './ImgBox.module.css';
import {
  markdown,
  handleLinkClick,
  disableIfCardComponent,
} from '../../utils/markdown';

const ImgBox = props => {
  const [hover, setHover] = useState(false);
  console.log(`✅`, props.src);
  const imgTag = (
    <img
      style={{
        maxWidth: `${props.maxWidth}`,
        maxHeight: `${props.maxHeight}`,
        minWidth: `${props.minWidth}`,
      }}
      src={`${process.env.PUBLIC_URL}${props.src}`}
      alt={props.alt}
      title={props.alt}
      className={`${!props.roundedOffImg && classes.rounded} ${
        props.multiply && classes.multiply
      }`}
    />
  );
  //==================================================================
  //==================================================================
  return (
    <React.Fragment>
      {disableIfCardComponent(props.omitMeta, props.preView) && (
        <div
          className={`${classes.imgBoxComponent} ${
            !props.roundedOffBox && classes.rounded
          } ${hover && classes.hover}`} //props.a &&
          style={{
            justifyContent: `${props.position}`,
            backgroundColor: `${props.backgroundColor}`,
            cursor: `${props.a ? 'Pointer' : ''}`,
          }}
          id={`${props.id}`}
          onClick={() => {
            if (props.a) handleLinkClick(props.a);
          }}
          onMouseEnter={() => {
            props.a ? setHover(true) : setHover(false);
          }}
          onMouseLeave={() => {
            props.a ? setHover(false) : setHover(false);
          }}
        >
          <div
            className={classes.imgBox}
            style={{
              justifyContent: `${props.positionImg}`,
            }}
          >
            <div>
              {props.link && <a href={`${props.link}`}>{imgTag}</a>}
              {!props.link && imgTag}
            </div>
          </div>
          <div>
            {markdown(props.heading)}
            {markdown(props.text)}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ImgBox;
