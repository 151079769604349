import { dateChangedOrder, dateToUnixTime } from './markdown';
// data
import { contentObject } from '../data/contentObject';
import { convertUrl } from './paramsIdToPage';
//==================================================================
export const readTextOfHTMLElement = (element, tag) => {
  if (element === null) return;
  const arrayOfHTMLElements = element.getElementsByTagName(tag);
  const stringsJoined = Array.prototype.slice
    .call(arrayOfHTMLElements)
    .map(el =>
      el.innerHTML
        .replace(/<a.*">/, '')
        .replace('</a>', '')
        .replace(/<img.*>/, '')
        .replace('<br>', '')
        .replace('&nbsp;', '')
        .replace('<strong>', '')
        .replace('</strong>', '')
    )
    .join(' ')
    .toLowerCase();
  return stringsJoined;
};

export const checkIfHtmlContainsSearchText = (
  searchTextArray,
  htmlStringText
) => {
  if (htmlStringText === undefined) return;
  // console.log('✅', htmlStringText);
  const result = searchTextArray
    .map(el => {
      return htmlStringText.includes(el);
    })
    .includes(true);
  // console.log('✅', result);
  return result;
};

export const htmlText = data => {
  return data.map(el => {
    // get element by id
    const elementById = document.getElementById(`${'blog-' + el.page}`);
    // search through all html elements of blog card
    const htmlStringText = ['h1', 'h2', 'h3', 'h4', 'h5', 'p']
      .map(el => readTextOfHTMLElement(elementById, el))
      .join(' ');
    if (htmlStringText === undefined) return el;
    el.htmlText = htmlStringText.toLowerCase();
    //==================================================================
    // add unixTime to use later on to order data
    if (el.date.created) {
      el.date.unix = dateToUnixTime(dateChangedOrder(el.date.created));
    }
    //==================================================================
    return el;
  });
};

//==================================================================

export const randomNumber = data => {
  const number = Number.isInteger(data) ? data : data.length;
  return Math.floor(Math.random() * (number - 1));
};

const randomData = (data, quantity) => {
  let dataRandom = [];
  let maxIteration = 1;
  const getRandom = (data, dataRandom) => {
    const randomItem = data[randomNumber(data)];
    if (dataRandom.some(item => item === randomItem)) return dataRandom;
    dataRandom = [...dataRandom, randomItem];
    return dataRandom;
  };
  while (dataRandom.length < quantity && maxIteration < 50) {
    dataRandom.length < quantity;
    dataRandom = getRandom(data, dataRandom);
    maxIteration = maxIteration + 1;
  }
  return dataRandom;
};

// random data for additional blog cards view
export const returnRandomDataSet = (allData, searchData, quantity) => {
  if (allData.length === searchData.length) return [];
  const filteredDataWithoutSearchResult = allData.filter(
    el => !searchData.some(element => element.page === el.page)
  );
  return randomData(filteredDataWithoutSearchResult, quantity);
};

// sidebar link - random page view without current page
export const randomBlogLinkWithoutCurrentPage = currentPage => {
  let dryLinkList = [];
  for (const [key, value] of Object.entries(contentObject)) {
    if (value.blogOmit === true) continue;
    if (convertUrl(value) === currentPage) continue;
    dryLinkList = [...dryLinkList, convertUrl(value)];
  }
  return dryLinkList[randomNumber(dryLinkList)];
};
//==================================================================
export const getTagsArray = data => {
  let tagsArrayAll = [];
  data.map(el => {
    el.tags.map(e => {
      tagsArrayAll = [...tagsArrayAll, e.trim()];
    });
  });
  const tagsWithoutDuplicate = [...new Set(tagsArrayAll)];
  const sortedArray = tagsWithoutDuplicate.sort((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });
  const filterCaseInsensitive = sortedArray.filter((el, i) => {
    const tagStringLowerCase = sortedArray[i - 1] || '';
    if (el.toLowerCase() !== tagStringLowerCase.toLowerCase()) return true;
  });
  return filterCaseInsensitive;
};
//==================================================================
