/* eslint-disable react/prop-types */
import React from 'react';
import classes from './Content.module.css';
import { readTextOfHTMLElement } from '../../utils/searchEngine.js';
import { motion, AnimatePresence } from 'framer-motion';

// // Store
// import { useSnapshot } from 'valtio';
// import { store } from '../../store/store';

const Content = props => {
  // const snap = useSnapshot(store);
  const content = document.getElementById('content');
  const htmlStringText = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'li']
    .map(el => readTextOfHTMLElement(content, el))
    .join(' ');

  const htmlTextLengthWithoutSpecialCharacters = htmlStringText
    .replace(/[^\w\s]/gi, '') // remove special characters
    .replace(/\s/g, ''); // remove spaces
  //==================================================================

  //==================================================================
  return (
    <AnimatePresence>
      <motion.div
        className={`${classes.content} `}
        id={'content'}
        key="content"
        initial={{ x: -1500, height: '1000px' }}
        animate={{
          x: 0,
          height: 'auto',
          transition: {
            delay: 0.25,
            type: 'spring',
            stiffness: 150,
            mass: 0.3,
          },
        }}
        exit={{ x: -1500 }}
      >
        {props.content}
        {props.dev && (
          <div className={classes.redingTimeBox}>
            <p>characters: {htmlTextLengthWithoutSpecialCharacters.length}</p>
            <p>brutto: {htmlStringText.length}</p>
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default Content;
