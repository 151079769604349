import React, { useState } from 'react';
import classes from './CookiesForm.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShield } from '@fortawesome/free-solid-svg-icons';
import { faXmarkSquare } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
// import { config } from '../../utils/constants';
import uuid from 'react-uuid';

const buttonAnimation = { type: 'spring', stiffness: 700 };

const CookiesForm = props => {
  const navigate = useNavigate();
  const [animation, setAnimation] = useState({
    x: 0,
    y: 0,
    scale: 1,
    bounce: 0,
  });
  const [cookiesSettings, setCookiesSettings] = useState(false);

  const [cookies, setCookies, removeCookie] = useCookies(['cookieConsent']);

  const CookieDate = new Date();
  CookieDate.setFullYear(CookieDate.getFullYear() + 10);

  const setCookieConsent = state => {
    const marketingCheckbox = document.getElementById('marketing');
    setCookies('cookieConsent', true, { path: '/', expires: CookieDate });
    if (state === 'essentiell') return; // on click essentiell stop here
    // if cookie settings are opened, check input state, if not checked stop here
    if (cookiesSettings) {
      if (!marketingCheckbox?.checked);
      return;
    }
    // if checked set all cookies
    setCookies('cookieConsentMarketing', true, {
      path: '/',
      expires: CookieDate,
    });
  };
  //==================================================================
  // motion animation

  //==================================================================
  // development
  const deleteCookieConsent = () => {
    removeCookie('cookieConsent', { path: '/' });
    removeCookie('cookieConsentMarketing', { path: '/' });
    removeCookie('_ga', { path: '/' });
    removeCookie('_gat', { path: '/' });
    removeCookie('_gid', { path: '/' });
  };
  //==================================================================
  const jsxCookieContent = (cookieName, cookieId, cookieContent) => {
    return (
      <React.Fragment>
        <label
          className={`${classes.gridCell} ${classes.gridCellLabel} ${
            cookiesSettings === cookieId && classes.cookieContentActive
          }`}
          onClick={() => {
            setCookiesSettings(cookiesSettings === cookieId ? true : cookieId);
          }}
        >
          {cookieName}
        </label>
        <div className={`${classes.gridCellInput}`}>
          {cookieId === 'essentiell' ? (
            <input type="checkbox" checked readOnly />
          ) : (
            <input id={cookieId} type="checkbox" />
          )}
        </div>
        {cookiesSettings === cookieId && (
          <div
            className={`${classes.gridCellX} `}
            onClick={() => {
              setCookiesSettings(true);
            }}
          >
            <FontAwesomeIcon icon={faXmarkSquare} />
          </div>
        )}
        {cookiesSettings === cookieId && (
          <div
            className={`${classes.cookieContent} ${
              cookiesSettings === cookieId && classes.cookieContentActive
            }`}
          >
            {cookieContent}
          </div>
        )}
      </React.Fragment>
    );
  };
  //==================================================================
  return (
    <motion.div
      className={classes.cookiesBoxPosition}
      initial={{ opacity: 0, y: -30, scale: 0, delay: '3s' }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: -30, scale: 0, delay: '3s' }}
    >
      <div className={`${classes.cookiesBox} ${classes.center} `}>
        <header className={`${classes.center} ${classes.header} `}>
          <FontAwesomeIcon icon={faShield} />

          <h3>Datenschutzeinstellungen</h3>
        </header>
        <p>
          Diese Seite nutzt Cookies um das Nutzererlebnis zu optimieren und die
          Funktion der Webseite zu gewährleisten. Cookies werden benötigt, damit
          technisch alles funktioniert und auch externe Inhalte gelesen werden
          können. Des weiteren sammeln wir Daten über aufgerufene Seiten und
          gelesene Blogartikel, um so unser Angebot zu Verbessern. Mehr über die
          verwendeten Dienste erfährst du unter den „Cookie-Einstellungen“. Mit
          Klick auf „Zustimmen und weiter“ erklärst du dich mit der Verwendung
          dieser Dienste einverstanden. Deine Einwilligung kannst du jederzeit
          mit Wirkung auf die Zukunft widerrufen oder ändern.
        </p>
        {cookiesSettings && (
          <div className={`${classes.cookieSettingsGridBox} `}>
            {jsxCookieContent(
              'Essentielle Cookies',
              'essentiell',
              essentiellContent
            )}
            {/* {jsxCookieContent('Besuchte Seiten und Links', 'link', linkContent)} */}
            {jsxCookieContent(
              'Marketing Cookies',
              'marketing',
              marketingContent
            )}
          </div>
        )}
        <div className={classes.buttonBox}>
          <motion.button
            whileHover={{ scale: 1.04 }}
            transition={buttonAnimation}
            className={`${classes.buttonConfirmNecessary} ${classes.button}`}
            onClick={() => {
              setCookieConsent('essentiell');
              navigate('/home');
            }}
          >
            Nur Essenzielle
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.06 }}
            transition={buttonAnimation}
            className={`${classes.buttonConfirm} ${classes.button}`}
            onClick={() => {
              setCookieConsent('all');
              navigate('/home');
            }}
          >
            OK weiter
          </motion.button>
        </div>
        <footer className={`${classes.center} ${classes.footer} `}>
          <button
            className={`${classes.buttonSettings}`}
            onClick={() => {
              setCookiesSettings(!cookiesSettings);
            }}
          >
            Cookie Einstellungen
          </button>
          <Link className={`${classes.link}`} to={`/impressum`}>
            Impressum
          </Link>
          <Link className={`${classes.link}`} to={`/datenschutz`}>
            Datenschutz
          </Link>
        </footer>
        {false || //config.dev ||
          (props.pageIdCookies && (
            <Link
              to={'/'}
              className={classes.deleteButton}
              onClick={deleteCookieConsent}
            >
              Cookies Löschen
            </Link>
          ))}
      </div>
    </motion.div>
  );
};

export default CookiesForm;

const cookieContentTable = content => {
  return (
    <div className={classes.cookieContentTableBox}>
      {content.map(cont => {
        return (
          <div key={uuid()}>
            <div className={classes.cookieContentTableHead}>{cont.head}</div>
            {cont.table.map(el => (
              <div key={uuid()} className={classes.cookieContentTable}>
                <div className={classes.tableId}>{el[0]} :</div>
                <div>{el[1]}</div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

const essentiellContent = cookieContentTable([
  {
    head: 'VG Wort',
    table: [
      [
        'Anbieter',
        'Verwertungsgesellschaft WORT (VG WORT), Rechtsfähiger Verein kraft Verleihung, Untere Weidenstraße 5, 81543 München, Deutschland',
      ],
      [
        'Zweck',
        'Das Cookie der VG Wort hilft die Kopierwahrscheinlichkeit unserer Texte zu ermitteln und stellt die Vergütung von gesetzlichen Ansprüchen von Autoren und Verlagen sicher. IP-Adressen werden nur in anonymisierter Form verarbeitet.',
      ],
      [
        'Datenschutzerklärung URL',
        'https://www.vgwort.de/hilfsseiten/datenschutz.html',
      ],
      ['Cookie Laufzeit', 'Sitzung'],
    ],
  },
  {
    head: 'Google Tag Manager',
    table: [
      ['Anbieter', '	Google LLC'],
      ['Zweck', 'Erweiterte Performanceorientierte Skriptsteuerung.'],
      ['Datenschutzerklärung URL', 'https://policies.google.com/privacy?hl=de'],
      ['Cookie Laufzeit', '2 Jahre'],
    ],
  },
  {
    head: 'Amazon Affiliate Links',
    table: [
      ['Anbieter', '	Amazon'],
      ['Zweck', 'Weiterleitung zur Produktpage von Amazon'],
      [
        'Datenschutzerklärung URL',
        'https://www.amazon.de/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ',
      ],
      // ['Cookie Laufzeit', '0 Jahre'],
    ],
  },
]);

const marketingContent =
  'Zum aktuellen Zeitpunkt werden keine Marketing Cookies erhoben! Wir behalten uns das Recht vor diese zu einem späteren Zeitpunkt zu integrieren!';
