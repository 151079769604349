/* eslint-disable no-unused-vars */
import { proxy, useSnapshot } from 'valtio';

const defaultState = {
  loading: false,
  page: 'home',
  meta: false,
  modal: false,
};

export const store = proxy({ ...defaultState });
