import React from 'react';
import classes from './ArtikelSeparator.module.css';
import { motion } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ArtikelSeparator = props => {
  return (
    <div className={classes.resultHeaderBox}>
      <motion.div
        className={`${classes.resultHeaderText}`}
        animate={props.controls}
        transition={{ duration: 0.4 }}
      >
        <div>{props.iconShow && <FontAwesomeIcon icon={props.icon} />}</div>
        <div className={classes.text}>{props.text}</div>
        <motion.div className={classes.text}>{props.integer}</motion.div>
      </motion.div>
    </div>
  );
};

export default ArtikelSeparator;
