import story01 from './01_unsereMotivation.md';
import story02 from './02_todo.md';
import story03 from './03_traumErfüllen.md';
import story04 from './04_reiseroute.md';
import story05 from './05_noch7Wochen.md';
//==================================================================
export const contentObjectStory = {
  //==================================================================
  // // // Story
  unsereMotivation: {
    page: [
      'unsere-motivation-zur-europareise',
      '/story/unsere-motivation-zur-europareise',
    ],
    displayName: 'Unsere Motivation zur Europareise',
    icon: '😍',
    sidebarOff: false,
    content: [{ type: 'md', content: story01 }],
    date: { created: '18.12.23' },
    sidebar: [],
    tags: ['story'],
  },
  todo: {
    page: ['unsere-todos', '/story/unsere-todos'],
    displayName: 'Unsere ToDo´s auf dem Weg ins Abenteuer',
    icon: '😍',
    sidebarOff: false,
    content: [{ type: 'md', content: story02 }],
    date: { created: '12.12.23' },
    sidebar: [],
    tags: ['story'],
  },
  traumErfüllen: {
    page: [
      'warum-einige-ihre-träume-erfüllen',
      '/story/warum-einige-ihre-träume-erfüllen',
    ],
    displayName: 'Warum einige Ihre Träume erfüllen und andere wiederum nicht!',
    icon: '🤔',
    sidebarOff: false,
    content: [{ type: 'md', content: story03 }],
    date: { created: '20.01.24' },
    sidebar: [],
    tags: ['story', 'motivation', 'mindset'],
  },
  reiseroute: {
    page: ['unsere-geplante-reiseroute', '/story/unsere-geplante-reiseroute'],
    displayName: 'Unsere geplante Reiseroute',
    icon: '😯',
    sidebarOff: false,
    content: [{ type: 'md', content: story04 }],
    date: { created: '02.03.24' },
    sidebar: [],
    tags: ['story', 'reiseroute'],
  },
  noch7Wochen: {
    page: [
      'noch-7-wochen-dann-gehts-los',
      '/story/noch-7-wochen-dann-gehts-los',
    ],
    displayName: 'Nur noch 7 Wochen',
    icon: '😍',
    sidebarOff: false,
    content: [{ type: 'md', content: story05 }],
    date: { created: '22.9.24' },
    sidebar: [],
    tags: ['story', 'reiseroute'],
  },
};
