import React from 'react';

const LinkEx = props => {
  return (
    <a href={`${props.l}`} target="_blank" rel="noreferrer" title={props.n}>
      {props.n}
    </a>
  );
};

export default LinkEx;
