import React, { useState } from 'react';
import classes from './Abfahrtskontrolle.module.css';
import allBlogPosts from '../pages/AllBlogPosts.module.css';
import MetaData from '../utils/MetaData';
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';

// todo:
// save to lokal Storage
// custom points
const defaultList = [
  'Fenster & Dachluke',
  'Schränke & Türen',
  'Kühlschrank',
  'Keile / Hubstützen',
  'Wasser & Abwasser',
  'Trittstufe',
  'Sat - Antenne',
  'Ladung gesichert',
  'Strom',
];

const Abfahrtskontrolle = () => {
  const [checked, setChecked] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [currentList, setCurrentList] = useState(defaultList);
  const [edit, setEdit] = useState(false);

  const handleChange = checkbox => {
    // remove if exists
    if (checkCurrentState(checkbox)) {
      setChecked(checked.filter(e => e !== checkbox));
    }
    // add if not exists
    if (!checkCurrentState(checkbox)) {
      setChecked([...checked, checkbox]);
    }
  };
  const editSwitch = (name, i) => {
    document.getElementById(i).value;
  };
  const checkCurrentState = checkbox => {
    return checked.includes(checkbox);
  };
  const userBox = (name, i) => {
    return (
      <label key={name + i}>
        <input
          type="checkbox"
          checked={checkCurrentState(name)}
          onChange={() => {
            handleChange(name);
          }}
        />
        {!edit && name}
        {edit && (
          <input
            type="text"
            id={i}
            // value={name}
            // onChange={() => {
            //   setTempList(pre => {
            //     pre[i] = document.getElementById(i).value;
            //     return [...pre];
            //   });
            //   console.log(`✅`, tempList[i]);
            // }}
          />
        )}
      </label>
    );
  };
  return (
    <div>
      <MetaData
        checked="omit"
        title="Abfahrtskontrolle"
        description="Abfahrtskontrolle"
      />
      <h1>Abfahrtskontrolle</h1>
      <br />
      <div className={classes.buttonDiv}>
        <motion.button
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 1 }}
          className={allBlogPosts.tags}
          onClick={() => {
            setTempList(currentList);
            edit == false ? setEdit(true) : setEdit(false);
          }}
        >
          {edit == false ? 'Liste bearbeiten' : 'Speichern'}
        </motion.button>
        {edit && (
          <motion.button
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 1 }}
            className={allBlogPosts.tags}
            onClick={() => {
              setEdit(false);
            }}
          >
            Abbrechen
          </motion.button>
        )}
        <motion.button
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 1 }}
          className={allBlogPosts.tags}
          onClick={() => {
            console.log(`Text`);
          }}
        >
          Alle Haken löschen
          {/* Alle Stellplätze {dataAbove11m.length} */}
        </motion.button>
      </div>
      <div className={classes.checkboxDiv}>
        {!edit && currentList.map((e, i) => userBox(e, i))}
        {edit && tempList.map((e, i) => userBox(e, i))}
      </div>
      <h2>Speichere diese Seite um die Checkliste immer parat zu haben</h2>
      <p>In Zukunft wird es hier folgende Möglichkeiten geben</p>
      <p>
        Die Abfahrtskontrolle ist mega wichtig, ich spreche aus Erfahrung. Wir
        haben vergessen, unsere Fenster zu verriegeln und haben bei voller Fahrt
        ein Fenster verloren. Zum Glück ist niemandem dabei was passiert. Sogar
        das Fenster konnten wir unbeschadet wieder einsammeln. Allerdings hat
        die Suche sich sehr lange hingezogen, da wir ein langes Stück Autobahn
        ablaufen mussten. Daher kann ich nur eindringlich, davor warnen Ohne
        ordentliche Abfahrtskontrolle loszufahren. Vorbereitungen außen am
        Wohnmobil o Alle Campingstühle und Campingtische zusammenklappen und
        diese im Wohnmobil verstauen. o Müll einsammeln und zusammen mit dem
        Müll aus dem RV in die Mülltonnen auf dem Campingplatz entsorgen. o
        Staufächer außen am Wohnmobil schließen und abschließen. Technik und
        Versorgung o Wasserpumpe ausschalten. o Gasofen ausschalten. o
        Klimaanlage ausschalten, bevor das RV vom Strom getrennt wird. o
        Frischwasser-Stand prüfen und ggf. auffüllen. o Abwasserstände prüfen
        und ggf. die Abwassertanks entleeren. o Bei den Abwassertanks immer erst
        den Schwarzwassertank (dickes Rohr) und danach den Grauwassertank
        (dünneres Rohr) entleeren. o Nach dem Entleeren den Abwasserschlauch
        abmontieren, ggf. spülen und verstauen. o Nach dem Entleeren des
        Abwassers, WC-Chemie in das WC geben und mit 2-3 Litern Wasser spülen. o
        Alle Anschlüsse (Frischwasser, Strom, Antenne) entfernen und die Kabel,
        Schläuche an den vorgesehenen Orten verstauen. o Regelmäßig den Ölstand
        am Wohnmobil checken. o Luftdruck in den Reifen regelmäßig checken.
        Vorbereitungen im Wohnmobil o Rollos und Vorhänge vor den Fenstern
        öffnen und ggf. mit den vorhandenen (Klett-) Bändern sichern. o Geräte
        und andere Gegenstände von der Arbeitsplatte wegräumen und sichern. o
        Töpfe, Geschirr und Gläser in die dafür vorgesehenen Stauräume packen. o
        Schranktüren und Schubladen komplett schließen und ggf. sichern. o
        Kühlschranktüre schließen. o Nach dem Trennen vom Stromnetz prüfen ob
        der Kühlschrank auf Gasbetrieb umgeschaltet hat. o Tür der Duschkabine
        sichern und WC-Türe schließen. o Ggf. weitere Schiebetüren sichern. o
        Fenster und (ganz wichtig) die Lüftungsklappen am Dach schließen. o
        Fernsehantenne oder Satellitenschüssel einfahren. o Fußraum vor der
        Fläche leeren, wo das Slide-Out einfährt. o Alle Slide-Outs am Wohnmobil
        einfahren. Abschließende Kontrollen o Alle Außentüren schließen und
        verriegeln. o Kontrollieren ob ausfahrbare Stufen an der Eingangstüre
        eingefahren und nicht blockiert sind. o RV von ggf. eingesetzten
        Niveau-Regulieren runterfahren und diese einpacken. o Einen
        abschließenden Rundgang im das Wohnmobil und den Stellplatz machen und
        nachschauen, ob alles verstaut ist. o Einen abschließenden Gang durch
        das RV machen und darauf achten, dass alle losen Gegenstände sicher
        verstaut sind und nicht runterfallen oder zerbrechen können.
      </p>
    </div>
  );
};

export default Abfahrtskontrolle;
