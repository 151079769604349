import AllBlogPosts from '../../../components/pages/AllBlogPosts';
import geldVerdienen10Möglichkeiten from './blogger/geldVerdienen10Möglichkeiten.md';
import bloggerWerden from './blogger/bloggerWerden.md';

export const contentObjectBlog = {
  //==================================================================
  blog: {
    page: ['blog', '/blog'],
    displayName: 'Blog',
    icon: '👉',
    sidebarOff: true,
    maxWidth: '100%',
    blogOmit: true,
    content: [{ type: 'react', content: AllBlogPosts }],
    tags: [],
  },
  alleBlogPosts: {
    page: ['alle-blog-posts', '/alle-blog-posts'],
    displayName: 'Alle Blog Posts',
    icon: '👉',
    sidebarOff: true,
    maxWidth: '100%',
    blogOmit: true,
    content: [{ type: 'react', content: AllBlogPosts }],
    tags: [],
    //==================================================================
  },
  geldVerdienen10Möglichkeiten: {
    page: [
      'geld-verdienen-mit-einem-blog',
      '/blog/geld-verdienen-mit-einem-blog',
    ],
    displayName: 'Als Blogger geld verdienen ',
    icon: '💲',
    content: [{ type: 'md', content: geldVerdienen10Möglichkeiten }],
    date: { created: '3.09.23' },
    tags: [
      'blogger werden',
      'geld verdienen',
      'blog',
      'einnahmequellen',
      'social-media',
    ],
  },
  bloggerWerden: {
    page: ['wie-kann-ich-blogger-werden', '/blog/wie-kann-ich-blogger-werden'],
    displayName: 'Blogger werden ',
    icon: '💲',
    content: [{ type: 'md', content: bloggerWerden }],
    date: { created: '05.09.23' },
    tags: [
      'blogger werden',
      'geld verdienen',
      'blog',
      'einnahmequellen',
      'social-media',
    ],
  },
};
