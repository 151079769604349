import React from 'react';
import uuid from 'react-uuid';
import classes from './LinkList.module.css';
// react router
import { Link } from 'react-router-dom';

const LinkList = props => {
  // optional if target outside of blogpage
  const setTarget = link => {
    if (link.startsWith('http')) return '_blank';
    return '';
  };
  return (
    <div className={classes.linkListBox}>
      <p className={classes.heading}>{props.addProps.heading}</p>
      <div className={classes.linkBox}>
        {props.addProps.links?.map(el => {
          //
          return (
            <div key={uuid()} className={classes.flexBoxes}>
              <div key={uuid()} className={classes.listCountIcon}>
                {el.icon ? el.icon : '◼'}
              </div>
              <Link
                to={el.link}
                key={uuid()}
                className={`${classes.aBox} ${classes.a}`}
                target={setTarget(el.link)}
                rel="noopener noreferrer"
              >
                {el.name}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LinkList;
