import React from 'react';

// handle markdown
import { fetchMd } from './fetchMd.js';
import { markdown } from './markdown.js';

import uuid from 'react-uuid';
import classes from '../App.module.css';

//==================================================================

const renderReactComponent = (ReactComponent, addProps, id, omitMeta) => {
  if (ReactComponent)
    return (
      <ReactComponent
        key={uuid()}
        addProps={addProps}
        toggleLoading=""
        id={id}
        className={classes.contentLiItem}
        omitMeta={omitMeta}
      ></ReactComponent>
    );
};

const renderMarkdown = async (mdPath, omitMeta, date) => {
  const res = await fetchMd(mdPath);
  return markdown(res, omitMeta, date);
};

export const renderContent = async e => {
  if (e.content === undefined) return;
  const summarizedContent = await Promise.all(
    e.content.map(async el => {
      if (el.type === 'md') {
        return await renderMarkdown(el.content, e.omitMeta, e.date);
      }
      if (el.type === 'react') {
        return renderReactComponent(el.content, el.addProps, el.id, e.omitMeta);
      }
    })
  );
  return summarizedContent;
};
