// constants.js
const prod = {
  scrollOmitDev: true,
  dev: false,
};
const dev = {
  scrollOmitDev: false,
  dev: true, // dev mode
  // dev: false, // test production mode
};

// eslint-disable-next-line no-undef
export const config = process.env.NODE_ENV === 'development' ? dev : prod;
