import React, { useState } from 'react';
import classes from './SocialMediaVideo.module.css';
import { markdown } from '../../utils/markdown.js';
import {
  YouTubeEmbed,
  TikTokEmbed,
  InstagramEmbed,
  FacebookEmbed,
} from 'react-social-media-embed';
import useWindowDimensions from '../../hooks/useWindowDimensions.js';

const SocialMedia = props => {
  const { height, width } = useWindowDimensions();
  //==================================================================
  const instCaption = input => {
    if (input)
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <InstagramEmbed url={props.instagram} width={328} captioned />
        </div>
      );
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <InstagramEmbed url={props.instagram} width={328} />
        test
      </div>
    );
  };
  //==================================================================
  return (
    <div
      className={`${classes.highLightBox} ${
        !props.roundedOff && classes.rounded
      } `}
      id={`${props.id}`}
    >
      {props.text && <div>{markdown(props.text, true, '')}</div>}
      {props.tiktok && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TikTokEmbed url={props.tiktok} width={325} />
        </div>
      )}
      {props.youTube && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <YouTubeEmbed
            url={props.youTube}
            width={width > 900 ? 780 : 500}
            height={width > 900 ? 439 : 281}
          />
          {/* 1,775956284153005 */}
        </div>
      )}
      {props.instagram && instCaption(props.instCaption)}
      {props.facebook && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <FacebookEmbed url={props.facebook} width={550} />
        </div>
      )}
    </div>
  );
};

export default SocialMedia;
