import React, { useState, useEffect } from 'react';
import classes from './ImgSliderBox.module.css';
import {
  markdown,
  splitPropToArray,
  disableIfCardComponent,
} from '../../utils/markdown';
import uuid from 'react-uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

const ImgSliderBox = props => {
  const [imgArray, setImgArray] = useState([]);
  const [currentImg, setCurrentImg] = useState('');
  const [currentImgFocus, setCurrentImgFocus] = useState(false);
  const [thumbnailCentered, setThumbnailCentered] = useState(true);

  useEffect(() => {
    const srcArray = splitPropToArray(props.src);
    setImgArray(srcArray);
    setCurrentImg(srcArray[0]);
  }, []);

  //==================================================================
  const toggleImgChange = (currentImg, imgArray, direction, img) => {
    if (img) setCurrentImg(img);
    const indexCurrentImg = imgArray.indexOf(currentImg);
    if (direction === 'right') {
      const index =
        imgArray.length === indexCurrentImg + 1 ? 0 : indexCurrentImg + 1;
      setCurrentImg(imgArray[index]);
    }
    if (direction === 'left') {
      const index =
        indexCurrentImg - 1 === -1 ? imgArray.length - 1 : indexCurrentImg - 1;
      setCurrentImg(imgArray[index]);
    }
    scrollThumbnailInToView();
  };
  //==================================================================
  // thumbnail scroll
  const scrollThumbnailInToView = () => {
    if (thumbnailCentered) return;
    const el = document.getElementById(currentImg);
    el?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  };
  useEffect(() => {
    if (currentImgFocus) scrollThumbnailInToView();
  }, [currentImg]);
  //==================================================================
  // thumbnail centered if possible
  const loaded = () => {
    setTimeout(() => {
      updateThumbnailCentered();
    }, 200);
  };
  window.addEventListener('load', loaded());

  const thumbnailWidth = document.getElementById(
    `${props.id}ThumbnailBox`
  )?.offsetWidth;
  const thumbnailScroll = document.getElementById(
    `${props.id}ThumbnailBox`
  )?.scrollWidth;

  let currentSize;
  window.addEventListener('resize', () => {
    currentSize = thumbnailWidth;
    setTimeout(() => {
      if (thumbnailWidth === currentSize) {
        updateThumbnailCentered();
      }
    }, 200);
  });

  useEffect(() => {
    updateThumbnailCentered();
  }, [imgArray]);

  const updateThumbnailCentered = () => {
    setThumbnailCentered(thumbnailWidth >= thumbnailScroll ? true : false);
  };

  //==================================================================
  //==================================================================
  return (
    <React.Fragment>
      {disableIfCardComponent(props.omitMeta, props.preView) && (
        <div
          key={props.id}
          id={`${props.id}Slider`}
          style={{
            backgroundColor: `${
              props.backgroundColor === 'none'
                ? '#f8f8f8'
                : props.backgroundColor
            }`,
          }}
          onFocus={() => {
            setCurrentImgFocus(true);
          }}
          className={`${classes.imgBoxComponent} ${
            !props.roundedOffBox && classes.rounded
          }`}
        >
          {props.heading && (
            <div className={classes.textBoxes}>{markdown(props.heading)}</div>
          )}
          {props.text && (
            <div className={`${classes.textBoxes} ${classes.textBox2}`}>
              {markdown(props.text)}
            </div>
          )}
          <div
            className={`${classes.imgBox} ${
              !props.roundedOffBox && classes.rounded
            }`}
            id={`${props.id}`}
            style={{
              maxWidth: `${props.maxWidth}`,
            }}
          >
            <button
              className={classes.flexBoxBtn}
              onClick={() => {
                toggleImgChange(currentImg, imgArray, 'left');
              }}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            </button>
            <div
              className={`${classes.currentImgBox} ${
                !props.roundedOffImg && classes.rounded
              } ${props.portrait && classes.currentImgBoxPortrait}`}
            >
              <img
                src={`${process.env.PUBLIC_URL}${currentImg}`}
                alt={props.alt}
                title={props.alt}
                className={` ${classes.img}`}
              />
            </div>
            <button
              className={`${classes.flexBoxBtn} ${classes.flexBoxBtnRe}`}
              onClick={() => {
                toggleImgChange(currentImg, imgArray, 'right');
              }}
            >
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </button>
          </div>
          {/* thumbnail //==================================================================*/}
          <div
            className={`${classes.imgThumbnailBox} ${
              thumbnailCentered === true
                ? classes.imgThumbnailBoxCentered
                : classes.imgThumbnailBoxScroll
            }`}
            id={`${props.id}ThumbnailBox`}
          >
            {imgArray.map(el => {
              return (
                <div
                  key={el}
                  id={el}
                  onClick={() => {
                    setCurrentImg(el);
                  }}
                  className={`${classes.thumbnailItem} ${
                    !props.roundedOffImg && classes.rounded
                  } ${currentImg === el ? classes.thumbnailItemActive : ''} ${
                    props.portrait && classes.thumbnailItemPortrait
                  }`}
                  style={{
                    maxWidth: `${props.maxWidthThumbnail}`,
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}${el}`}
                    alt={props.alt}
                    title={props.alt}
                    className={` ${props.multiply && classes.multiply}`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ImgSliderBox;
