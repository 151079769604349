import React, { useState, useEffect } from 'react';
import classes from './Sidebar.module.css';
import logo from '../../assets/img/logo.png';
import uuid from 'react-uuid';

import { motion } from 'framer-motion';

import {
  paypal,
  blogSuggestionDefault,
  mostRead,
  goodVibes,
  dice,
  separator,
} from '../../data/pageContent/sidebar/contentObjectSidebar.js';

// handle render
import { renderContent } from '../../utils/renderContent.js';

import { convertUrl } from '../../utils/paramsIdToPage';
// read contentObject and render markdown / react components, add to object,render to page
import {
  allBlogPosts,
  filteredByTags,
} from '../../utils/renderContentToObjectFilterBlogPosts.js';
//==================================================================

// get page tag read near Tags, and also random
class suggestionLink {
  constructor(name, icon, link) {
    this.name = name;
    this.icon = icon;
    this.link = link;
  }
}
const linksDefault = [
  { name: 'Elterngeld', icon: '💲', link: '/auszeit/elterngeld' },
  { name: 'Elternzeit', icon: '⏱', link: '/auszeit/elternzeit' },
  {
    name: 'Blogger werden',
    icon: '🎁',
    link: '/blog/geld-verdienen-mit-einem-blog',
  },
];

const Sidebar = props => {
  const [contentSidebarState, setContentSidebarState] = useState([]);
  //==================================================================
  const currentPageTags = props.currentPage.tags || [];
  const dataFilteredOmit = allBlogPosts(props.data) || [];
  const dataFilteredByTags = filteredByTags(dataFilteredOmit, currentPageTags);
  //==================================================================
  const [blogSuggestion, setBlogSuggestion] = useState(blogSuggestionDefault);

  const defaultOrder = [
    dice,
    separator,
    blogSuggestion,
    separator,
    goodVibes,
    separator,
    mostRead,
    paypal,
  ];
  const paypalOrder = [
    mostRead,
    separator,
    dice,
    separator,
    goodVibes,
    separator,
    blogSuggestion,
    paypal,
  ];
  //==================================================================
  const setRenderContent = async content => {
    const renderedContent = await renderContent({ content });
    setContentSidebarState(renderedContent);
  };
  //==================================================================
  // handle sidebar suggestion links by current page tags
  const blogSuggestionByTags = (data, tags) => {
    if (data.length === 0 || tags.length === 0) {
      setBlogSuggestion(pre => {
        pre.addProps.links = linksDefault;
        return { ...pre };
      });
      return;
    }
    const links = data.map(el => {
      return new suggestionLink(el.displayName, el.icon, convertUrl(el));
    });
    setBlogSuggestion(pre => {
      pre.addProps.links = links;
      return { ...pre };
    });
  };

  //==================================================================
  // handle order change by sort method
  useEffect(() => {
    blogSuggestionByTags(dataFilteredByTags, currentPageTags);
    if (props.currentPage?.sidebarOrder === 'paypal') {
      setRenderContent(paypalOrder);
      return;
    }
    setRenderContent(defaultOrder);
  }, [props.currentPage]);

  //==================================================================
  // // // const remainingHeight = document.getElementById(
  // // //   'remainingSidebarSpace'
  // // // )?.offsetHeight;
  // // // const numberOfPictures = Math.floor(remainingHeight / 600);
  // // // console.log('✅', numberOfPictures);
  // // // useEffect(() => {
  // // //   setTimeout(waitLoad, 50);
  // // // }, [numberOfPictures]);

  // // // const waitLoad = () => {
  // // //   setAdBox(picRandomNumberWithoutDuplicate(numberOfPictures));
  // // // };

  // // // const picRandomNumberWithoutDuplicate = number => {
  // // //   if (numberOfPictures === undefined) return;
  // // //   if (number > 20) {
  // // //     return [
  // // //       1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  // // //     ];
  // // //   }
  // // //   let arrayOfNumbers = [];
  // // //   let i = 0;
  // // //   while (arrayOfNumbers.length <= number) {
  // // //     const ranNub = randomNumber(number);
  // // //     if (!arrayOfNumbers.includes(ranNub)) {
  // // //       arrayOfNumbers = [...arrayOfNumbers, ranNub];
  // // //     }
  // // //     i++;
  // // //     if (i >= 500) break;
  // // //   }
  // // //   return arrayOfNumbers;
  // // // };
  //==================================================================
  //==================================================================
  const variants =
    window.innerWidth > 1150
      ? {
          animate: {
            x: 0,
            transition: {
              delay: 0.5,
              // delay: 0.25,
              type: 'spring',
              stiffness: 150,
              mass: 0.3,
            },
          },
          exit: { x: 800 },
        }
      : {
          animate: {
            y: 0,
            transition: {
              delay: 0.5,
              duration: 1,
            },
          },
          exit: { y: 800 },
        };
  //==================================================================
  //==================================================================
  return (
    <motion.div
      key="sidebar"
      className={classes.sidebar}
      variants={{ sidebarBelow: { y: 800 } }}
      initial={variants.exit}
      animate={variants.animate}
      // exit={variants.exit}
    >
      {contentSidebarState}
      {/* <div id={'remainingSidebarSpace'} className={classes.advertisingBox}>
        <div className={classes.flexGrowBox}>
          {adBox.map(el => {
            return (
              <Img
                key={uuid()}
                src={`${process.env.PUBLIC_URL}${goodVibePictures[el]}`}
                alt={''}
                maxHeight={'300px'}
                maxWidth={'100%'}
              ></Img>
            );
          })}
        </div>
      </div> */}
      <img className={classes.sidebarLogo} src={logo} alt="WomoDaddy-Logo" />
    </motion.div>
  );
};

export default Sidebar;
