import React from 'react';
import classes from './Dice.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDice } from '@fortawesome/free-solid-svg-icons';

import { randomBlogLinkWithoutCurrentPage } from '../../utils/searchEngine.js';
import { Link } from 'react-router-dom';

const Dice = () => {
  const currentUrl = window.location.pathname;
  return (
    <Link
      to={`${randomBlogLinkWithoutCurrentPage(currentUrl)}`}
      className={classes.linkBox}
    >
      <div className={classes.textBox}>
        <FontAwesomeIcon icon={faDice} className={classes.diceIcon} />
        &nbsp;
        <p className={classes.text}>Zufallsbeitrag öffnen ... ?</p>
      </div>
    </Link>
  );
};

export default Dice;
