//
export const paramsIdToPage = (data, paramsId) => {
  const objectKeys = Object.keys(data);
  if (objectKeys.includes(paramsId)) return paramsId;
  //==================================================================
  const [elementKey] = objectKeys.filter(el => data[el].page[0] === paramsId);
  if (elementKey) return elementKey;
  //==================================================================
  // catch go back to home => return page = home
  if (paramsId === undefined) return 'home';
  //==================================================================
  else return 'error';
};

export const convertUrl = element => {
  if (element === undefined || element === false) return '/home';
  const url = element.page[1] === undefined ? element.page[0] : element.page[1];
  return url;
};
