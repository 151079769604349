//==================================================================
// // // Auszeit
import auszeit from './auszeit.md';
import elternzeit from './elternzeit.md';
import elterngeld from './elterngeld.md';
import anleitung from './anleitung.md';
import variantenFinanzen from './variantenFinanzen/variantenFinanzen.md';
import budgetPlanung from './budgetPlanung.md';

//==================================================================

export const contentObjectAuszeit = {
  auszeit: {
    page: ['auszeit', '/auszeit'],
    displayName: 'Auszeit',
    icon: '😍',
    sidebarOff: false,
    content: [{ type: 'md', content: auszeit }],
    date: { created: '29.08.23' },
    tags: ['auszeit', 'elternzeit'],
  },
  elternzeit: {
    page: ['elternzeit', '/auszeit/elternzeit'],
    displayName: 'Elternzeit',
    icon: '⏱',
    content: [{ type: 'md', content: elternzeit }],
    date: { created: '15.08.23' },
    tags: ['auszeit', 'elternzeit'],
  },
  elterngeld: {
    page: ['elterngeld', '/auszeit/elterngeld'],
    displayName: 'Elterngeld',
    icon: '💲',
    content: [{ type: 'md', content: elterngeld }],
    date: { created: '20.08.23' },
    tags: ['auszeit', 'elternzeit'],
  },
  anleitung: {
    page: ['anleitung', '/auszeit/anleitung'],
    displayName: 'Anleitung zu deiner Auszeit',
    icon: '🎁',
    content: [{ type: 'md', content: anleitung }],
    date: { created: '25.08.23' },
    tags: ['anleitung', 'elternzeit', 'elterngeld', 'auszeit'],
  },
  variantenFinanzen: {
    page: ['varianten-und-finanzen', '/auszeit/varianten-und-finanzen'],
    displayName: 'Varianten und Finanzen zu deiner Auszeit',
    icon: '💲',
    content: [{ type: 'md', content: variantenFinanzen }],
    date: { created: '28.08.23' },
    tags: ['auszeit', 'elternzeit', 'elterngeld'],
  },
  budgetPlanung: {
    page: [
      'unsere-persönliche-budgetplanung',
      '/auszeit/unsere-persönliche-budgetplanung',
    ],
    displayName: 'Unsere persönliche Budgetplanung',
    icon: '💲',
    offline: false,
    blogOmit: false,
    content: [{ type: 'md', content: budgetPlanung }],
    date: { created: '03.03.24' },
    tags: [
      'auszeit',
      'elternzeit',
      'elterngeld',
      'ausgaben',
      'Einnahmequellen',
      'Geld verdienen',
    ],
  },
};
