import React, { useState, useEffect } from 'react';
import classes from './ImgBoxMultiPicture.module.css';
import {
  markdown,
  splitPropToArray,
  disableIfCardComponent,
} from '../../utils/markdown';
import uuid from 'react-uuid';

const ImgBoxMultiPicture = props => {
  const [imgArray, setImgArray] = useState([]);

  useEffect(() => {
    const srcArray = splitPropToArray(props.src);
    setImgArray(srcArray);
  }, []);

  //==================================================================
  //==================================================================
  return (
    <React.Fragment>
      {disableIfCardComponent(props.omitMeta, props.preView) && (
        <div
          className={`${classes.imgBoxComponent} ${
            !props.roundedOffBox && classes.rounded
          } `}
        >
          <div className={classes.textBox}>
            {markdown(props.heading)}
            {markdown(props.text)}
          </div>
          <div
            className={`${classes.imgBoxComponent} ${
              !props.roundedOffBox && classes.rounded
            } `}
            style={{
              backgroundColor: `${props.backgroundColor}`,
            }}
            id={`${props.id}`}
          >
            <div
              className={classes.imgGridBox}
              style={{
                justifyContent: `${props.positionImg}`,
                gridTemplateColumns: `repeat(auto-fit, minmax(${
                  props.minWidth ? props.minWidth : '20rem'
                }, max-content)`,
              }}
            >
              {imgArray.map(el => {
                return (
                  <div key={uuid()}>
                    <img
                      src={`${process.env.PUBLIC_URL}${el}`}
                      alt={props.alt}
                      title={props.alt}
                      className={`${!props.roundedOffImg && classes.rounded} ${
                        props.multiply && classes.multiply
                      }`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {props.textBelow && (
            <div
              className={`${classes.textBox} ${
                props.textBelowLeft && classes.textBelowLeft
              }`}
            >
              {markdown(props.textBelow)}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default ImgBoxMultiPicture;
