import React, { useEffect, useState } from 'react';
import classes from './ChartMd.module.css';
import uuid from 'react-uuid';
import {
  splitPropToArray,
  splitPropByAsteriskToArray,
  dotToComma,
} from '../../../src/utils/markdown';

// TODO template
const gridTemplateColumns = 'minmax(min-content, auto)';

const ChartMd = props => {
  const [rowHeader, setRowHeader] = useState({ row: [] });
  const [contentArray, setContentArray] = useState([]);
  const [rowFooter, setRowFooter] = useState({ row: [] });
  const [rowFooter2, setRowFooter2] = useState({ row: [] });
  const [gridTemplateAuto, setGridTemplateAuto] = useState([]);
  const [gridTemplateUserDefined, setGridTemplateUserDefined] = useState([]);

  const readColumnLength = [];
  useEffect(() => {
    setRowHeader({ row: splitPropToArray(props.rowHeader) });
    const rowAsterisk = splitPropByAsteriskToArray(props.content);
    const contentRows = rowAsterisk.map(el => ({
      row: dotToComma(splitPropToArray(el)),
    }));
    setContentArray(contentRows);
  }, []);
  useEffect(() => {
    setRowFooter({ row: dotToComma(splitPropToArray(props.rowFooter)) });
    setRowFooter2({ row: dotToComma(splitPropToArray(props.rowFooter2)) });
    for (let i = 0; i < rowHeader.row.length; i++)
      readColumnLength.push(gridTemplateColumns);
    setGridTemplateAuto(readColumnLength);
  }, [rowHeader]);

  useEffect(() => {
    if (props.gridTemplateColumns === undefined) {
      setGridTemplateUserDefined(gridTemplateAuto);
      return;
    }
    let temp = gridTemplateAuto;
    const userTemplateColumnsSplit = props.gridTemplateColumns
      .split('/')
      .map(el => el.split('-'));
    userTemplateColumnsSplit.map(
      el => (temp[el[0]] = `minmax(min-content, ${el[1]})`)
    );
    setGridTemplateUserDefined(temp);
  }, [gridTemplateAuto]);

  // let chart = props.Chart || defaultChart;
  let chart = {
    gridTemplateColumns: gridTemplateUserDefined.join(' '),
    tableHeader: {
      text: props.tableHeaderText || defaultChart.tableHeader.text,
      h: props.tableHeaderTextSize || defaultChart.tableHeader.h,
    },
    rowHeader: rowHeader || defaultChart.rowHeader,
    content: contentArray || defaultChart.contentArray,
    rowFooter: rowFooter,
    rowFooter2: rowFooter2,
  };

  const tableHeader = int => {
    switch (int) {
      case '1':
        return <h1>{chart.tableHeader?.text}</h1>;
      case '2':
        return <h2>{chart.tableHeader?.text}</h2>;
      case '4':
        return <h4>{chart.tableHeader?.text}</h4>;
      default:
        return <h3>{chart.tableHeader?.text}</h3>;
    }
  };
  //

  //==================================================================
  return (
    <div className={classes.chartBox} id={props.id}>
      {chart.tableHeader?.text && tableHeader(chart.tableHeader?.h)}

      <div
        className={classes.chart}
        style={{
          gridTemplateColumns: `${chart.gridTemplateColumns}`,
        }}
      >
        {/* //================================================================== */}
        {chart.rowHeader?.row.map(el => (
          <header
            key={uuid()}
            className={`${classes.item} ${classes.itemHead}`}
          >
            {el}
          </header>
        ))}

        {/* //================================================================== */}
        {chart.content?.map(e =>
          e.row.map(el => (
            <div key={uuid()} className={`${classes.item} `}>
              {el}
            </div>
          ))
        )}

        {/* //================================================================== */}
        {chart.rowFooter?.row.map(el => (
          <div
            key={uuid()}
            className={`${classes.item} ${classes.itemFooter} `}
          >
            {el}
          </div>
        ))}
        {/* //================================================================== */}
        {chart.rowFooter2?.row.map(el => (
          <div
            key={uuid()}
            className={`${classes.item} ${classes.itemFooter} `}
          >
            {el}
          </div>
        ))}
        {/* //================================================================== */}
      </div>
    </div>
  );
};

export default ChartMd;

const defaultChart = {
  tableHeader: { text: '', h: '3' },
  rowHeader: { row: ['loading', '...', '...'] },
  content: [
    { row: ['loading', '...', '...'] },
    { row: ['loading', '...', '...'] },
    { row: ['loading', '...', '...'] },
  ],
  // rowFooter: { row: ['', 'Sum', '12345'] },    // possible example
};
