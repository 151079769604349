import React from 'react';
import classes from './BlogPostCard.module.css';

import { setSlugId } from '../../../src/utils/scrollToContent.js';
import { convertUrl } from '../../../src/utils/paramsIdToPage';
// react router
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { nav } from '../../data/nav.js';

const BlogPostCard = props => {
  //==================================================================
  // after loading page inject id´s from md
  const waitLoad = () => {
    setSlugId();
  };
  window.onload = setTimeout(waitLoad, 50);
  // console.log(`Text`, nav);
  //==================================================================
  const checkIfNavContains = (currentPage, navList) => {
    // if navList do not contain  "canonical"
    if (navList.dropdown.some(e => e.page == currentPage.page[0])) {
      // console.log(`✅ dropdown contains`, currentPage.page[0]);
      return;
      // return 'canonical';
    }
    // add string split / take last array element
    navList.dropdown.map(e => {
      // console.log(`✅`, convertUrl(e.dropdown.page));
      console.log(`✅`, e.dropdown.page, currentPage.page[0]);
      if (e.dropdown.some(ee => ee.page == currentPage.page[0])) {
        console.log(`✅ dropdown contains`, e.page, currentPage.page[0]);
        return;
      }
    });

    // console.log(`Canonical`, currentPage.page[0]);
    return '';
  };
  //==================================================================
  return (
    <motion.div
      animate={props.controls}
      transition={{ type: 'slide' }}
      id={props.id}
      className={`${classes.card} `}
      onClick={() => {
        if (props.id === 'noResults') props.abortQuery();
      }}
    >
      <Link
        to={convertUrl(props.page)}
        rel={checkIfNavContains(props.page, nav)}
        // rel="canonical"
        className={`${classes.link}`}
      ></Link>
      <div className={classes.fadeBox}>
        <div id="cardBox" className={`${classes.cardBox} `}>
          {props.content}
        </div>
        <div className={classes.bottomFade}></div>
      </div>
    </motion.div>
  );
};

export default BlogPostCard;
