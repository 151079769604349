import React from 'react';
import classes from './Countdown.module.css';

const Countdown = props => {
  const dateNow = new Date();
  const targetDate = new Date(props.targetDate);
  const distanceToTarget = targetDate - dateNow;
  // const month = Math.floor(distanceToTarget / (1000 * 60 * 60 * 24 * 30));
  const days = Math.floor(distanceToTarget / (1000 * 60 * 60 * 24));
  const positiveDays = days * -1;

  return (
    <div className={classes.box}>
      {distanceToTarget > 0 ? (
        <div className={classes.textHBox}>
          <h3 className={classes.CountdownHeader}>Der Countdown läuft !</h3>
          <h3>
            Noch <span className={classes.days}>{days}</span> Tage bis das
            Abenteuer beginnt !
          </h3>
        </div>
      ) : (
        <div className={classes.textHBox}>
          {/* <h3 className={classes.CountdownHeader}>Timer !</h3> */}
          <h3>
            Seit <span className={classes.days}>{positiveDays}</span> Tagen sind
            wir auf Europareise !
          </h3>
        </div>
      )}
    </div>
  );
};

export default Countdown;
