import React, { useState, useEffect } from 'react';
import LeafletMap from '../map/LeafletMap';
import classes from './Map.module.css';
import MetaData from '../utils/MetaData';
// react router
import { useSearchParams } from 'react-router-dom';

import LeafletMyPos from '../map/LeafletMyPos';
// import marker from '../map/MarkerComponent.module.css';
import allBlogPosts from '../pages/AllBlogPosts.module.css';
import dataAbove11mx1 from '../../data/mapData/camping/dataAbove11m.json';
import dataAbove11m from '../../data/mapData/camping/data11.json';

// import Search from 'react-leaflet-search';
// import Search from 'react-leaflet-search/lib/Search-v1';
// import Search from 'react-leaflet-search';
// import ReactLeafletSearch from 'react-leaflet-search';

import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';

//==================================================================

//==================================================================
// import A50 from '../../data/mapData/camping/50.json';
// import result1 from '../../data/mapData/camping/result1.json';
//==================================================================

// todo:
// error or failure report by mail
// search function, text search place address else
// filter function
// tiktok name search
// category for kids for camper activity
// disable video preview
// scroll throw video search results
// search area radius

// additional show the world where you are, marker on map, slider for size,

const Map = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const mapParams = searchParams.get('map');

  // console.log(`✅`, myPosition[0]?.long.replace(/"/gi, '').trim());
  //==================================================================

  //==================================================================
  // const searchItems = A50;
  // const results = result1;
  //==================================================================
  // // map data
  // console.log(`✅✅✅✅`, dataAbove11mx1);
  // var test = [];
  // console.log(
  //   `✅`,
  //   dataAbove11mx1.map(el => {
  //     const newObj = el;
  //     // console.log(`✅`, el.geo.lat);
  //     if (el.geo.lat) {
  //       newObj.geo = [el.geo.lat, el.geo.long];
  //       // console.log(`❌`, test);
  //       test = [...test, newObj];
  //     }
  //     return newObj;
  //   })
  // );
  //==================================================================
  const [data, setData] = useState(dataAbove11m);
  const filterFav = () => {
    setData(dataAbove11m.filter(el => favList.some(elm => elm == el.id)));
  };
  //==================================================================
  const localFavList = localStorage.getItem('wdFavList');
  const [favList, setFavList] = useState(JSON.parse(localFavList) || []);
  useEffect(() => {
    localStorage.setItem('wdFavList', JSON.stringify(favList));
    if (favList.length == 0) setData(dataAbove11m);
  }, [favList]);
  // localStorage.clear('wdFavList');
  //==================================================================
  // const myPos = true;
  const myPos = false;
  return (
    <div className={classes.map}>
      <MetaData
        checked="omit"
        title="WomoDaddy Stellplatzkarte für Wohnmobilfahrer, Stellplatzfinder"
        description="Stellplätze für große Wohnmobile - Auf dieser interaktiven Stellplatzkarte findet jeder einen tollen Platz für sein Wohnmobilfahrer oder Wohnwagen."
      />
      <h1>Interaktive Karte für Womo-Fahrer</h1>
      <br />
      <h2>Diese Karte zeigt Stellplätze für Fahrzeuge größer 11m</h2>
      {favList.length > 0 && (
        <div className={classes.buttonDiv}>
          <motion.button
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 1 }}
            className={allBlogPosts.tags}
            onClick={filterFav}
          >
            Favoriten {favList.length}
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 1 }}
            className={allBlogPosts.tags}
            onClick={() => {
              setData(dataAbove11m);
            }}
          >
            Alle Stellplätze
            {/* Alle Stellplätze {dataAbove11m.length} */}
          </motion.button>
        </div>
      )}
      <br />
      <div className={`${classes.leafletOuterBox}`}>
        <div className={`${classes.leafletBox} leaflet-container`}>
          {!myPos && (
            <LeafletMap
              setFavList={setFavList}
              favList={favList}
              data={data}
            ></LeafletMap>
          )}
          {myPos && (
            <LeafletMyPos
              setFavList={setFavList}
              favList={favList}
              data={data}
            ></LeafletMyPos>
          )}
        </div>
      </div>
      <h2>Beta-Status ! Die Seite befindet sich zur Zeit im Aufbau</h2>
      <p>In Zukunft wird es hier folgende Möglichkeiten geben</p>
      <ul>
        <li>Suchfunktion mit Filter</li>
        <li>
          Verschiedene Listen (Kostenfreie Plätze, Für Familien, Für große
          Womo´s)
        </li>
        <li>Details zu Punkten (Preise, Stellplatzdetails, usw.)</li>
      </ul>
      <br />
      Die aktuellen Stellplatzdaten sind ungeprüft übernommen aus einer
      gesammelten Liste von Plätzen für Fahrzeuge über 11 Meter.
      <br />
      <br />
      Ich werde die Daten prüfen und fehlende Informationen ergänzen.
      <br />
      <br />
      Also sei gespannt und speicher dir diese Seite um schon bald hier
      spannende Infos zu finden.
      <br />
      <br />
      <br />
    </div>
  );
};

export default Map;
